import { SxProps, Theme } from '@mui/material/styles';

import { colorsUI } from 'constants/colorsUI';
import { CSSProperties } from 'react';

const { black500 } = colorsUI;

export const clientsNoneDataImageStyles: SxProps<Theme> = {
   width: '100%',
   maxWidth: {
      sm: '500px',
      xs: 'none',
   },
   height: {
      md: '390px',
      xs: '270px',
   },
   objectFit: 'contain',
};

export const clientsNoneDataRTextStyles: SxProps<Theme> = {
   widht: '100%',
   maxWidth: '647px',
   color: black500,
   textAlign: 'center',
};

export const clientsNoneDataBoxStyles: SxProps<Theme> = {
   display: 'flex',
   gap: '4px',
   justifyContent: 'center',
   flexDirection: {
      sm: 'row',
      xs: 'column',
   },
   alignItems: 'center',
};

export const clientsNoneDataButtonStyles: CSSProperties = {
   padding: '12px',
   fontSize: '1rem',
};
