import React, {
   createContext,
   FC,
   useState,
   useRef,
   useEffect,
   DragEvent,
   SetStateAction,
   Dispatch,
} from 'react';
import { Graph } from '@antv/x6';
import { register } from '@antv/x6-react-shape';
import { Transform } from '@antv/x6-plugin-transform';
import { Wrapper, Field, Blocks, BlockDrag } from './tree.css';
import { IBlock, BlockSelect } from 'interfaces/constructor/block';
import { blockTypes } from '../../constant/blockArr';
import { useAddTreeMutation, useTreeQuery } from 'app/dashboard/store/api/treeApi';
import SideMenu from '../SideMenu/SideMenu';

interface IContextTree {
   blocks: IBlock[];
   editBlock: Dispatch<SetStateAction<IBlock[]>>;
   openModal: Dispatch<SetStateAction<boolean>>;
   currentBlock: IBlock | null;
   handleEditBlock: (block: IBlock) => void;
   deleteBlock: (id: number) => void;
}

interface IEdge {
   id: string;
   source: string;
   target: string;
   color?: string;
   sourcePort?: string;
   targetPort?: string;
}

interface TreeProps {
   slug: string;
   setIsSaveEnabled: (enabled: boolean) => void;
   handleSave: (handleSave: () => void) => void;
}

export const ContextTree = createContext<IContextTree>({
   blocks: [],
   editBlock: () => {},
   openModal: () => {},
   currentBlock: null,
   handleEditBlock: () => {},
   deleteBlock: () => {},
});

const Tree: FC<TreeProps> = ({ slug, setIsSaveEnabled, handleSave }) => {
   const [blocks, setBlocks] = useState<IBlock[]>([]);
   const [edges, setEdges] = useState<IEdge[]>([]);
   const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
   const containerRef = useRef<HTMLDivElement>(null);
   const graphRef = useRef<Graph>();
   const [currentBlock, setCurrentBlock] = useState<IBlock | null>(null);
   const [isSelectPropertiesOpen, setIsSelectPropertiesOpen] = useState(false);
   const [selectedBlockType, setSelectedBlockType] = useState<'node' | 'reaction' | null>(
      null,
   );
   const [stages, setStages] = useState<string[]>([]);
   const { data, refetch } = useTreeQuery({ slug });

   console.log(data);

   const NodeComponent = ({ node }: { node: any }) => {
      const data = node.getData();
      return (
         <div
            style={{
               height: '100%',
               textAlign: 'center',
               lineHeight: '50px',
               borderRadius: '8px 8px 8px 0',
               padding: '0px 6px',
               background: data.color || '#efdbff',
            }}
         >
            {data.title || ''}
         </div>
      );
   };

   register({
      shape: 'custom-react-node',
      component: NodeComponent,
      width: 220,
      height: 60,
   });

   const [addTree] = useAddTreeMutation();

   useEffect(() => {
      const storedStages = localStorage.getItem(`stages_${slug}`);
      if (storedStages) {
         setStages(JSON.parse(storedStages));
      }
   }, [slug]);

   useEffect(() => {
      const storedStages = localStorage.getItem(`stages_${slug}`);
      if (storedStages) {
         setStages(JSON.parse(storedStages));
      }
   }, [slug]);

   const handleAddStage = (newStage: string) => {
      setStages((prev) => {
         const updatedStages = [...prev, newStage];
         localStorage.setItem(`stages_${slug}`, JSON.stringify(updatedStages));
         return updatedStages;
      });
   };

   const buildTree = (nodeId: number, processedNodes: Set<number> = new Set()): any => {
      if (processedNodes.has(nodeId)) {
         return null;
      }
      processedNodes.add(nodeId);

      const node = blocks.find((block) => block.id === nodeId);

      if (!node) return null;

      const childrenEdges = edges.filter((edge) => edge.source === nodeId.toString());
      const children = childrenEdges
         .map((edge) => buildTree(parseInt(edge.target), processedNodes))
         .filter(Boolean);

      if (node.type === 'reaction' && node.properties?.reaction_type === 'objection') {
         children.forEach((child) => {
            if (child.type === 'node') {
               child.properties.in_objection = '1';
            }
         });
      }

      const treeNode = {
         id: node.id,
         type: node.type,
         properties:
            node.type === 'node'
               ? {
                    text: node.properties?.text || '',
                    hint: node.properties?.hint || '',
                    stage: node.properties?.stage || '',
                    ...(node.properties?.in_objection === '1' && {
                       in_objection: '1',
                    }),
                 }
               : {
                    text: node.properties?.text || '',
                    reaction_type: node.properties?.reaction_type || 'positive',
                    ...(node.properties?.exodus && { exodus: node.properties.exodus }),
                 },
         children: children || [],
      };

      return treeNode;
   };

   const saveTreeToApi = async () => {
      const rootBlock = blocks.find(
         (block) => !edges.some((edge) => edge.target === block.id.toString()),
      );

      if (!rootBlock) {
         return;
      }

      const tree = buildTree(rootBlock.id);

      console.log('Данные для сохранения:', tree);

      try {
         const response = await addTree({
            slug,
            data: tree,
         }).unwrap();
         refetch();
         graphRef.current?.removeCells(graphRef.current?.getCells());
         localStorage.removeItem(`blocks_${slug}`);
         localStorage.removeItem(`edges_${slug}`);
         localStorage.removeItem(`stages_${slug}`);
      } catch (error: any) {
         console.error('Ошибка при сохранении дерева:', error);
      }
   };

   const getBlockColor = (block: { type: string; properties?: any }): string => {
      if (block.type === 'reaction') {
         if (block.properties?.exodus === 'success') {
            return '#0ec96f';
         }
         if (block.properties?.exodus === 'failure') {
            return '#e00b0bea';
         }
         switch (block.properties?.reaction_type) {
            case 'positive':
               return '#A8E6CF';
            case 'negative':
               return '#FF8B94';
            case 'objection':
               return '#DABFFF';
         }
      }

      return '#cde8ff';
   };

   const checkIfScriptIsComplete = (
      updatedBlocks: IBlock[] = blocks,
      updatedEdges: IEdge[] = edges,
   ): boolean => {
      const lastBlocks = updatedBlocks.filter(
         (block) => !updatedEdges.some((edge) => edge.source === block.id.toString()),
      );

      const allLastReactionsHaveExodus = lastBlocks.every((block) =>
         block.type === 'reaction' ? !!block.properties?.exodus : false,
      );

      const hasLastNode = lastBlocks.some((block) => block.type === 'node');

      return allLastReactionsHaveExodus && !hasLastNode;
   };

   useEffect(() => {
      setIsSaveEnabled(checkIfScriptIsComplete(blocks, edges));
   }, [blocks, edges]);

   const saveDataToLocalStorage = (blocks: IBlock[], edges: IEdge[]) => {
      const currentBlocks = graphRef.current?.getNodes().map((node) => {
         const data = node.getData() as IBlock;
         const { width, height } = node.size();
         return {
            ...data,
            x: node.getPosition().x,
            y: node.getPosition().y,
            width,
            height,
            color: data.color || getBlockColor(data),
         };
      });

      if (currentBlocks) {
         localStorage.setItem(`blocks_${slug}`, JSON.stringify(currentBlocks));
      }

      const currentEdges = graphRef.current?.getEdges().map((edge) => {
         const edgeColor = edge.attr('line/stroke') || '#FF910F';
         return {
            id: edge.id,
            source: edge.getSourceCellId(),
            target: edge.getTargetCellId(),
            sourcePort: edge.getSourcePortId(),
            targetPort: edge.getTargetPortId(),
            color: edgeColor,
         };
      });

      if (currentBlocks && currentEdges) {
         localStorage.setItem(`blocks_${slug}`, JSON.stringify(currentBlocks));
         localStorage.setItem(`edges_${slug}`, JSON.stringify(currentEdges));
      }

      console.log('Данные сохранены в localStorage:', {
         blocks: currentBlocks,
         edges: currentEdges,
      });
   };

   const loadDataFromLocalStorage = () => {
      const storedBlocks = localStorage.getItem(`blocks_${slug}`);
      const storedEdges = localStorage.getItem(`edges_${slug}`);

      if (storedBlocks) {
         const parsedBlocks: IBlock[] = JSON.parse(storedBlocks);
         setBlocks(parsedBlocks);

         parsedBlocks.forEach((block) => {
            addNodeToGraph(block);
         });
      }

      if (storedEdges) {
         const parsedEdges: IEdge[] = JSON.parse(storedEdges);
         setEdges(parsedEdges);

         parsedEdges.forEach((edge) => {
            graphRef.current?.addEdge({
               id: edge.id,
               source: {
                  cell: edge.source,
                  port: edge.sourcePort || 'out',
               },
               target: {
                  cell: edge.target,
                  port: edge.targetPort || 'in',
               },
               attrs: {
                  line: {
                     stroke: edge.color || '#FF910F',
                     strokeWidth: 2,
                  },
               },
            });
         });
      }
   };

   const addEdgeToGraph = (edge: IEdge) => {
      graphRef.current?.addEdge({
         id: edge.id,
         source: {
            cell: edge.source,
            port: 'out',
         },
         target: {
            cell: edge.target,
            port: 'in',
         },
         attrs: {
            line: {
               stroke: edge.color || '#FF910F',
               strokeWidth: 2,
               targetMarker: {
                  name: 'block',
                  args: { size: 6 },
               },
            },
         },
      });
   };

   const loadBlocksFromApi = (apiResponse: any) => {
      if (
         !apiResponse ||
         !Array.isArray(apiResponse.data) ||
         apiResponse.data.length === 0
      ) {
         return;
      }

      const nodeData = apiResponse.data[0];
      const verticalSpacing = 150;
      const horizontalSpacing = 300;
      const levelPositions: { [level: number]: number } = {};

      let localBlocks: IBlock[] = [];
      const localEdges: IEdge[] = [];

      const traverseNode = (
         node: any,
         parentId: number | null = null,
         level: number = 0,
      ) => {
         if (!node || typeof node !== 'object' || !node.id) {
            console.error('Некорректный узел:', node);
            return;
         }

         levelPositions[level] = levelPositions[level] || 0;

         const x = levelPositions[level] * horizontalSpacing;
         const y = level * verticalSpacing;

         levelPositions[level]++;

         const block: IBlock = {
            id: node.id,
            title: node.properties?.text || 'Без названия',
            type: node.type || 'node',
            x,
            y,
            width: node.properties?.width || 220,
            height: node.properties?.height || 60,
            color: getBlockColor({
               type: node.type || 'node',
               properties: node.properties || {},
            }),
            children: node.children || [],
            parent: { id: parentId },
            properties: node.properties || {},
         };

         localBlocks.push(block);
         addNodeToGraph(block);

         if (parentId !== null) {
            const parentBlock = localBlocks.find((b) => b.id === parentId);
            const edgeColor = getBlockColor({
               type: parentBlock?.type || 'node',
               properties: parentBlock?.properties || {},
            });

            const edge: IEdge = {
               id: `edge-${parentId}-${node.id}`,
               source: parentId.toString(),
               target: node.id.toString(),
               color: edgeColor,
            };

            localEdges.push(edge);
            addEdgeToGraph(edge);

            setEdges((prev) => [...prev, edge]);
            graphRef.current?.addEdge({
               id: edge.id,
               source: edge.source,
               target: edge.target,
               attrs: {
                  line: {
                     stroke: edgeColor,
                     strokeWidth: 2,
                  },
               },
            });
         }

         if (Array.isArray(node.children)) {
            node.children.forEach((child: any) =>
               traverseNode(child, node.id, level + 1),
            );
         }
      };

      traverseNode(nodeData);
      setBlocks(localBlocks);
      setEdges(localEdges);
   };

   useEffect(() => {
      if (data) {
         loadBlocksFromApi(data);
      }
   }, [data]);

   useEffect(() => {
      handleSave(() => saveTreeToApi());
   }, [blocks, edges]);

   const addNodeToGraph = (block: IBlock) => {
      const color = getBlockColor(block);
      graphRef.current?.addNode({
         id: String(block.id),
         x: block.x,
         y: block.y,
         width: block.width || 220,
         height: block.height || 60,
         shape: 'custom-react-node',
         data: { ...block, color },
         attrs: {
            body: {
               fill: color,
               stroke: '#FF910F',
            },
            label: {
               text: block.title || '',
               fill: '#000',
               fontSize: 12,
               textAnchor: 'middle',
               textVerticalAnchor: 'middle',
            },
         },
         ports: {
            items: [
               { id: 'in', group: 'in' },
               { id: 'out', group: 'out' },
            ],
            groups: {
               in: {
                  position: 'top',
                  attrs: {
                     circle: {
                        r: 6,
                        magnet: 'passive',
                        stroke: '#FF910F',
                        strokeWidth: 2,
                        fill: '#fff',
                     },
                  },
               },
               out: {
                  position: {
                     name: 'absolute',
                     args: {
                        x: '1',
                        y: '100%',
                     },
                  },
                  markup: '<rect/>',
                  attrs: {
                     rect: {
                        magnet: true,
                        stroke: '#FF910F',
                        strokeWidth: 2,
                        fill: '#fff',
                        rx: 6,
                     },
                  },
               },
            },
         },
         resizable: true,
      });
   };

   const handleEditBlock = (block: IBlock) => {
      setCurrentBlock(block);
      setIsModalOpen(true);
   };

   const updateNodeColor = (block: IBlock) => {
      const node = graphRef.current?.getCellById(String(block.id));
      if (node) {
         node.attr({
            body: {
               fill: block.color,
            },
         });
      }
   };

   blocks.forEach(updateNodeColor);

   const deleteBlock = (id: number) => {
      setBlocks((prev) => prev.filter((block) => block.id !== id));
      setEdges((prev) =>
         prev.filter(
            (edge) => edge.source !== id.toString() && edge.target !== id.toString(),
         ),
      );
      setBlocks((prevBlocks) => {
         const updatedBlocks = prevBlocks.filter((block) => block.id !== id);
         checkIfScriptIsComplete(updatedBlocks, edges);
         return updatedBlocks;
      });

      graphRef.current?.getCellById(id.toString())?.remove();
   };

   const onDrop = (event: DragEvent) => {
      event.preventDefault();

      const block: BlockSelect = JSON.parse(event.dataTransfer.getData('blockType'));
      const { offsetX, offsetY } = event.nativeEvent;

      if (graphRef.current?.getNodes().length === 0 && block.type !== 'node') {
         alert('Первый добавляемый блок должен быть узлом.');
         return;
      }

      const newBlock: IBlock = {
         id: Date.now(),
         title: '',
         type: block.type,
         x: offsetX,
         y: offsetY,
         parent: { id: null },
         children: [],
         color: getBlockColor({ type: block.type, properties: {} }),
         properties: {
            type: block.type,
            reaction_type: 'positive',
         },
      };

      setBlocks((prevBlocks) => {
         const updatedBlocks = [...prevBlocks, newBlock];
         saveDataToLocalStorage(updatedBlocks, edges);
         return updatedBlocks;
      });

      addNodeToGraph(newBlock);

      setSelectedBlockType(block.type);
      setIsSelectPropertiesOpen(true);
      setCurrentBlock(newBlock);
   };

   const handleSaveProperties = (properties: Partial<IBlock>) => {
      if (!currentBlock) return;

      const updatedBlock: IBlock = {
         ...currentBlock,
         properties: { ...currentBlock.properties, ...properties },
         title: properties.text || currentBlock.title,
         color: getBlockColor({
            ...currentBlock,
            properties: { ...currentBlock.properties, ...properties },
         }),
      };

      setBlocks((prevBlocks) =>
         prevBlocks.map((block) => (block.id === updatedBlock.id ? updatedBlock : block)),
      );

      const node = graphRef.current?.getCellById(updatedBlock.id.toString());
      if (node) {
         node.setData(updatedBlock);
         node.attr({
            label: {
               text: updatedBlock.title,
            },
            body: {
               fill: updatedBlock.color,
            },
         });
      }

      updateEdgeColors(updatedBlock);
   };

   const resizingOptions = {
      enabled: true,
      minWidth: 100,
      minHeight: 60,
      maxWidth: 400,
      maxHeight: 300,
      restrict: true,
      preserveAspectRatio: false,
   };

   const rotatingOptions = {
      enabled: false,
   };

   useEffect(() => {
      if (containerRef.current && !graphRef.current) {
         const graph = new Graph({
            container: containerRef.current,
            grid: true,
            panning: {
               enabled: true,
               eventTypes: ['leftMouseDown', 'mouseWheel'],
            },
            mousewheel: {
               enabled: true,
               modifiers: ['ctrl', 'meta'],
            },
            connecting: {
               snap: true,
               allowBlank: false,
               highlight: true,
               connector: 'rounded',
               connectionPoint: 'anchor',
               router: {
                  name: 'manhattan',
               },
               createEdge() {
                  return graphRef.current!.createEdge({
                     attrs: {
                        line: {
                           stroke: '#FF910F',
                           strokeWidth: 2,
                           targetMarker: {
                              name: 'block',
                              args: { size: 6 },
                           },
                        },
                     },
                  });
               },
               validateConnection({ sourceCell, targetCell }) {
                  if (!sourceCell || !targetCell) {
                     return false;
                  }

                  const sourceData = sourceCell.getData() as IBlock;
                  const targetData = targetCell.getData() as IBlock;

                  if (sourceData?.properties?.exodus) {
                     return false;
                  }

                  if (
                     sourceData?.type === 'reaction' &&
                     targetData?.type === 'reaction'
                  ) {
                     return false;
                  }

                  if (sourceData?.type === 'reaction' && targetData?.type !== 'node') {
                     return false;
                  }

                  if (sourceData?.type === 'reaction') {
                     const outgoingEdges = graphRef.current?.getOutgoingEdges(sourceCell);
                     const hasChildNode = outgoingEdges?.some((edge) => {
                        const targetCell = graphRef.current?.getCellById(
                           edge.getTargetCellId(),
                        );
                        const targetBlock = targetCell?.getData() as IBlock;
                        return targetBlock?.type === 'node';
                     });

                     if (hasChildNode) {
                        return false;
                     }
                  }

                  return true;
               },
            },
         });

         graph.on('node:resize', ({ node }) => {
            if (!node) {
               console.error('Ошибка: узел не найден');
               return;
            }

            const resizedBlockId = node.id;
            const newWidth = node.size().width;
            const newHeight = node.size().height;

            setBlocks((prevBlocks) => {
               const updatedBlocks = prevBlocks.map((block) =>
                  block.id.toString() === resizedBlockId
                     ? { ...block, width: newWidth, height: newHeight }
                     : block,
               );

               saveDataToLocalStorage(updatedBlocks, edges);

               console.log(
                  `Блок ${resizedBlockId} изменён: width=${newWidth}, height=${newHeight}`,
               );
               return updatedBlocks;
            });
         });

         graph.on('node:dblclick', ({ node }) => {
            const blockData = node.getData() as IBlock;
            setCurrentBlock(blockData);
            setSelectedBlockType(blockData.type);
            setIsSelectPropertiesOpen(true);
         });

         graph.on('edge:connected', ({ edge, isNew }) => {
            if (!isNew) return;

            const sourceId = edge.getSourceCellId();
            const targetId = edge.getTargetCellId();

            const sourceCell = graphRef.current?.getCellById(sourceId);
            const targetCell = graphRef.current?.getCellById(targetId);

            if (!sourceCell || !targetCell) {
               edge.remove();
               return;
            }

            const sourceData = sourceCell.getData() as IBlock;
            const edgeColor = sourceData?.color || '#FF910F';

            edge.attr({
               line: {
                  stroke: edgeColor,
                  strokeWidth: 2,
                  targetMarker: {
                     name: 'block',
                     args: { size: 6 },
                  },
               },
            });

            const newEdge: IEdge = {
               id: edge.id,
               source: sourceId,
               target: targetId,
               color: edgeColor,
            };

            setEdges((prevEdges) => {
               const updatedEdges = [...prevEdges, newEdge];
               saveDataToLocalStorage(blocks, updatedEdges);
               return updatedEdges;
            });
         });

         graph.use(
            new Transform({
               resizing: resizingOptions,
               rotating: rotatingOptions,
            }),
         );

         graphRef.current = graph;
      }

      loadDataFromLocalStorage();
   }, [slug]);

   const updateEdgeColors = (block: IBlock) => {
      const outgoingEdges = edges.filter((edge) => edge.source === block.id.toString());

      outgoingEdges.forEach((edge) => {
         const edgeCell = graphRef.current?.getCellById(edge.id);
         if (edgeCell) {
            edgeCell.attr({
               line: {
                  stroke: block.color || '#FF910F',
                  strokeWidth: 2,
               },
            });
         }
      });
   };

   useEffect(() => {
      if (blocks.length > 0 && edges.length > 0) {
         const finalBlocks = blocks.filter((block) => block.properties?.exodus);

         finalBlocks.forEach((block) => {
            const outgoingEdges = edges.filter(
               (edge) => edge.source === block.id.toString(),
            );
            outgoingEdges.forEach((edge) => {
               graphRef.current?.getCellById(edge.id)?.remove();
               setEdges((prevEdges) => prevEdges.filter((e) => e.id !== edge.id));
            });
         });
      }
   }, [blocks, edges]);

   useEffect(() => {
      if (data && Array.isArray(data.data) && data.data.length > 0) {
         graphRef.current?.removeCells(graphRef.current?.getCells());
         setBlocks([]);
         setEdges([]);

         loadBlocksFromApi(data);
      }
   }, [data]);

   return (
      <ContextTree.Provider
         value={{
            blocks,
            editBlock: setBlocks,
            openModal: setIsModalOpen,
            currentBlock,
            handleEditBlock,
            deleteBlock,
         }}
      >
         <Wrapper>
            <Blocks>
               {blockTypes.map((block, index) => (
                  <BlockDrag
                     key={index}
                     $blockType={block.type}
                     draggable
                     $colorIndex={index}
                     onDragStart={(e) =>
                        e.dataTransfer.setData('blockType', JSON.stringify(block))
                     }
                  >
                     {block.text}
                  </BlockDrag>
               ))}
            </Blocks>
            <Field
               ref={containerRef}
               onDrop={onDrop}
               onDragOver={(e) => e.preventDefault()}
            />
         </Wrapper>

         {isSelectPropertiesOpen && selectedBlockType && currentBlock && (
            <SideMenu
               blockType={selectedBlockType}
               currentBlock={currentBlock}
               onSave={handleSaveProperties}
               onClose={() => {
                  setIsSelectPropertiesOpen(false);
                  setCurrentBlock(null);
               }}
               onDelete={deleteBlock}
               stages={stages}
               onAddStage={handleAddStage}
            />
         )}
      </ContextTree.Provider>
   );
};

export default Tree;
