import { CSSProperties } from 'react';
import { SxProps, Theme } from '@mui/material/styles';

export const headerBoxFormStyles: CSSProperties = {
   display: 'flex',
   justifyContent: 'center',
};

export const headerBoxModileStyles: SxProps<Theme> = (theme: Theme) => ({
   display: 'flex',
   justifyContent: 'space-between',
   flexDirection: 'row',
   alignItems: 'center',
   gap: '21px',

   [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      justifyContent: 'center',
      flexWrap: 'nowrap',
   },
});
