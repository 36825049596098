import { CSSProperties } from 'styled-components';

import { colorsUI } from '@constants/colorsUI';

const { black200, black500, smokeGray } = colorsUI;

export const historyScriptHeadStyles: CSSProperties = {
   color: smokeGray,
   background: black200,
   padding: '20px',
   justifyContent: 'start',
   lineHeight: '2rem',
   textTransform: 'uppercase',
};

export const historyScriptItemStyles: CSSProperties = {
   flexDirection: 'column',
   alignItems: 'start',
};

export const historyScriptDescStyles: CSSProperties = {
   color: black200,
   lineHeight: '1.5rem',
};

export const historyScriptTextStyles: CSSProperties = {
   color: black500,
   fontWeight: 700,
   lineHeight: '1.5rem',
   textTransform: 'uppercase',
};

export const historyScriptTextLightStyles: CSSProperties = {
   color: black500,
   fontWeight: 400,
   textTransform: 'lowercase',
};

export const historyScriptFooterStyles: CSSProperties = {
   alignItems: 'start',
   gap: '24px',
};
