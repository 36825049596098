import React, { useRef } from 'react';

import { useTheme } from '@hooks/useTheme';

import { TableContainer, Typography, Grid, Box, Paper } from '@mui/material';
import { TableCheckboxProps } from '../types';
import {
   tableStyles,
   tableHeaderStyles,
   tableHeaderCellStyles,
   tableRowCellStyles,
   tableCellStyles,
   tableCellAdviceMobileStyles,
} from '../styles';

import { colorsUI } from '@constants/colorsUI';

const { light50 } = colorsUI;

export const TableCheckboxMobile = <T extends Record<string, any>>({
   columns,
   rows,
   onSelect,
   selected,
   customId,
}: TableCheckboxProps<T>) => {
   const ID = customId ? customId : 'id';

   const handleSelect = (id: number | string) => {
      onSelect((prevSelected) =>
         prevSelected.includes(id)
            ? prevSelected.filter((item) => item !== id)
            : [...prevSelected, id],
      );
   };

   const isSelected = (id: number | string) => selected.includes(id);

   const { theme } = useTheme();

   const touchTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

   const handleTouchStart = (id: number | string) => {
      touchTimeoutRef.current = setTimeout(() => {
         handleSelect(id);
      }, 500);
   };

   const handleTouchEnd = () => {
      if (touchTimeoutRef.current) {
         clearTimeout(touchTimeoutRef.current);
         touchTimeoutRef.current = null;
      }
   };

   return (
      <TableContainer sx={tableStyles} component={Paper}>
         {rows.map((row) => (
            <Grid
               container
               key={row[ID]}
               sx={{
                  padding: '8px 0',
                  background: isSelected(row[ID]) ? light50 : '',
               }}
               onTouchStart={() => handleTouchStart(row[ID])}
               onTouchEnd={handleTouchEnd}
               onTouchCancel={handleTouchEnd}
            >
               {columns.map((column) => (
                  <React.Fragment key={String(column.id)}>
                     {column?.isNoneRenderCell && column.renderCell ? (
                        <>
                           <Grid
                              sx={{
                                 ...(tableHeaderStyles({ theme }) as object),
                                 ...tableCellStyles,
                              }}
                              item
                              xs={6}
                           >
                              <Typography
                                 sx={{ ...tableHeaderCellStyles({ theme }) }}
                                 variant="subtitle2"
                              >
                                 {column.label}
                              </Typography>
                           </Grid>
                           <Grid sx={{ ...tableCellStyles }} item xs={6}>
                              <Box sx={{ ...tableRowCellStyles({ theme }) }}>
                                 {column.renderCell(row)}
                              </Box>
                           </Grid>
                        </>
                     ) : column?.renderCell ? (
                        <Grid sx={{ ...tableCellStyles }} item xs={12}>
                           <Box sx={{ ...tableRowCellStyles({ theme }) }}>
                              {column.renderCell(row)}
                           </Box>
                        </Grid>
                     ) : (
                        <>
                           <Grid
                              sx={{
                                 ...(tableHeaderStyles({ theme }) as object),
                                 ...tableCellStyles,
                              }}
                              item
                              xs={6}
                           >
                              <Typography
                                 sx={{ ...tableHeaderCellStyles({ theme }) }}
                                 variant="subtitle2"
                              >
                                 {column.label}
                              </Typography>
                           </Grid>
                           <Grid item xs={6}>
                              <Typography
                                 sx={{
                                    ...tableCellStyles,
                                    ...tableRowCellStyles({ theme }),
                                 }}
                                 variant="body1"
                              >
                                 {row[column.id as keyof T] ?? '-'}
                              </Typography>
                           </Grid>
                        </>
                     )}
                  </React.Fragment>
               ))}
               <Grid item xs={12}>
                  <Typography sx={tableCellAdviceMobileStyles} variant="overline">
                     Подсказка, удерживайте палец на блоке, чтобы его выделить
                  </Typography>
               </Grid>
            </Grid>
         ))}
      </TableContainer>
   );
};
