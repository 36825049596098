import React from 'react';

import { useTheme } from '@hooks/useTheme';
import { useRuntimeLogsByUiidData } from '@hooks/hooksApi/logs';

import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ErrorMessage } from '@shared/errorMessage';
import { ColumnDashboard } from '@shared/column';
import { ProfileHeader } from '@componentsDashBoard/profileHeader';
import { HistoryScript } from '@componentsDashBoard/historyScript';
import { ButtonAction, ButtonGoBack } from '@shared/buttons';
import { LoaderDefault } from '@shared/loader';

import { ReactComponent as ArrowIcon } from '@assets/icons/arrow.svg';

import { ReactComponent as DownloadIcon } from '@assets/icons/download.svg';

import {
   logsHistoryButtonStyles,
   logsHistoryHeaderStyles,
   logsHistoryHeaderInnerStyles,
   logsHistoryButtonGoBackStyles,
   logsHistoryHeaderButtonInnerStyles,
} from './style';

export const LogsHistory = () => {
   const { slug } = useParams();

   const { theme } = useTheme();

   const {
      runtimeLogHistoryData,
      runtimeLogHistorytIsLoading,
      runtimeLogHistoryIsError,
      runtimeLogHistoryIsSuccess,
      runtimeLogHistoryError,
   } = useRuntimeLogsByUiidData({ uuid: slug! });

   //console.log(runtimeLogHistoryError?.data?.message);
   // error в запросе не несет никакой инфы для юзера тока показывает где сломлаось

   if (runtimeLogHistoryIsError) return <ErrorMessage message={'error!'} />;

   return (
      <ColumnDashboard gap={'32px'}>
         <ProfileHeader
            styles={{ ...logsHistoryHeaderStyles(theme) }}
            stylesText={{ lineHeight: '2.5rem' }}
            title={`Логи ${slug}`}
         >
            <Box sx={{ ...logsHistoryHeaderInnerStyles(theme) }}>
               <ButtonGoBack
                  sx={{ ...logsHistoryButtonGoBackStyles(theme) }}
                  Icon={ArrowIcon}
               >
                  Вернуться назад
               </ButtonGoBack>
               <Box sx={{ ...logsHistoryHeaderButtonInnerStyles(theme), flexGrow: 2 }}>
                  <ButtonAction
                     sx={{ ...logsHistoryButtonStyles(theme) }}
                     Icon={DownloadIcon}
                  >
                     Выгрузить в CVS
                  </ButtonAction>
               </Box>
            </Box>
         </ProfileHeader>

         {runtimeLogHistorytIsLoading ? (
            <LoaderDefault stylesInner={{ justifyContent: 'center' }} />
         ) : (
            <HistoryScript
               data={runtimeLogHistoryData!}
               isSuccess={runtimeLogHistoryIsSuccess}
            />
         )}
      </ColumnDashboard>
   );
};
