import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useProjectUpdate, useProjectBySlugData } from '@hooks/hooksApi/project';
import { useToggleModal as useTogglAlert } from '@hooks/hooksUI';

import { AlertDefault } from '@shared/alert';
import { Box } from '@mui/material';
import { ColumnDashboard } from '@shared/column';
import { ProfileHeader } from '@componentsDashBoard/profileHeader';
import { FormProject } from '../forms/formProject';
import { LoaderDefault } from '@shared/loader';

import { compareObjects, findKeyByValue } from '@utils/objectUtils';

import type { IProjectCreate } from '@projectTypes/projects/projectTypes';

import { headerBoxModileStyles, headerBoxFormStyles } from './style';

import {
   businessAreaLabels,
   contactBaseTypeLabels,
   scenarioTypeLabels,
} from '@constants/options/projects';

export const UpdateProjects = () => {
   const { slug } = useParams();

   const { isOpen: isOpenNotificationsAlert, setIsOpen: setIsOpenNotificationsAlert } =
      useTogglAlert();

   const {
      data,
      error: errorProjectBySlug,
      isError: isErrorProjectBySlug,
      isSuccess: isSuccessProjectBySlug,
      isLoading: isLoadingProjectBySlug,
   } = useProjectBySlugData({ slug });
   const {
      updateProjectData,
      errorUpdateProject,
      isErrorUpdateProject,
      isLoadingUpdateProject,
      isSuccessUpdateProject,
   } = useProjectUpdate();

   const onSubmit = async (formData: IProjectCreate) => {
      const oldData = {
         contact_base_type: data?.data.contact_base_type,
         scenario_type: data?.data.scenario_type,
         business_area: data?.data.business_area,
         name: data?.data.name,
      };

      const newData = {
         contact_base_type: contactBaseTypeLabels[formData.contact_base_type],
         scenario_type: scenarioTypeLabels[formData.scenario_type],
         business_area: businessAreaLabels[formData.business_area],
         name: formData.name,
      };

      const isSame = compareObjects({
         objFirst: newData,
         objLast: oldData,
      });

      if (!isSame) {
         await updateProjectData({ data: formData, slug });
         setIsOpenNotificationsAlert(false);
      } else {
         setIsOpenNotificationsAlert(true);
      }
   };

   const businessArea = findKeyByValue({
      obj: businessAreaLabels,
      value: data?.data.business_area,
   });

   const scenarioType = findKeyByValue({
      obj: scenarioTypeLabels,
      value: data?.data.scenario_type,
   });

   const contactBaseType = findKeyByValue({
      obj: contactBaseTypeLabels,
      value: data?.data.contact_base_type,
   });

   return (
      <ColumnDashboard>
         <ProfileHeader
            styles={headerBoxModileStyles as object}
            title="Редактирование проекта"
         ></ProfileHeader>
         {isErrorProjectBySlug && (
            <AlertDefault
               severity="error"
               message={errorProjectBySlug.data.data.message}
            />
         )}
         {isLoadingProjectBySlug && (
            <LoaderDefault stylesInner={{ justifyContent: 'center' }} />
         )}

         <Box sx={headerBoxFormStyles}>
            {isSuccessProjectBySlug && (
               <FormProject
                  isOpenNotificationsAlert={isOpenNotificationsAlert}
                  isNotifications={true}
                  successMessage="Проект успешно обвнолен!"
                  isNotificationsMessage="Введите новые данные"
                  isSuccess={isSuccessUpdateProject}
                  name={data?.data.name}
                  businessArea={businessArea}
                  contactBaseType={contactBaseType}
                  scenarioType={scenarioType}
                  error={errorUpdateProject}
                  isError={isErrorUpdateProject}
                  isLoading={isLoadingUpdateProject}
                  onSubmit={onSubmit}
                  buttonSubmitText="Применить изменения"
               />
            )}
         </Box>
      </ColumnDashboard>
   );
};
