import { useAddProjectMutation } from 'app/dashboard/store/api/projectApi';

import type { IProjectCreate } from '@projectTypes/projects/projectTypes';
import { IErrorResponse } from '@projectTypes/apiTypes';

export const useProjectCreate = () => {
   const [createProject, { isError, error, isLoading, isSuccess }] =
      useAddProjectMutation();

   const createProjectData = async (data: IProjectCreate) => {
      try {
         const result = await createProject(data).unwrap();
         console.log(result);
         return result;
      } catch (err) {
         const customError = err as IErrorResponse;
         console.log(customError);

         if ('data' in customError) {
            console.error('Ошибка API:', customError.data.data.message);
            throw new Error(customError.data.data.message);
         } else {
            console.error('Системная ошибка:', err);
            throw new Error('Неизвестная ошибка');
         }
      }
   };

   return {
      createProjectData,
      isError,
      error: error as IErrorResponse,
      isLoading,
      isSuccess,
   };
};
