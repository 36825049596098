import { SxProps, Theme } from '@mui/material';

import { colorsUI } from '@constants/colorsUI';

const { light600, transperent } = colorsUI;

export const logsHistoryHeaderStyles = (theme: Theme): SxProps<Theme> => ({
   gap: '8px',
   [theme.breakpoints.down('md')]: {
      gap: '16px',
   },
});

export const logsHistoryHeaderInnerStyles = (theme: Theme): SxProps<Theme> => ({
   display: 'flex',
   justifyContent: 'end',
   alignItems: 'center',
   gap: '8px',
   flexGrow: 4,
   [theme.breakpoints.down('md')]: {
      gap: '16px',
      alignItems: 'stretch',
      width: '100%',
      flexDirection: 'column-reverse',
   },
});

export const logsHistoryHeaderButtonInnerStyles = (theme: Theme): SxProps<Theme> => ({
   display: 'flex',
   justifyContent: 'end',
   alignItems: 'center',
   gap: '8px',
   [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
   },
});

export const logsHistoryButtonGoBackStyles = (theme: Theme): SxProps<Theme> => ({
   color: light600,
   background: transperent,
   paddingLeft: '4px',
   paddingRight: '4px',
   [theme.breakpoints.down('sm')]: {
      width: '100%',
   },
});

export const logsHistoryButtonStyles = (theme: Theme): SxProps<Theme> => ({
   [theme.breakpoints.down('sm')]: {
      width: '100%',
   },
});
