import React, { FC } from 'react';

import { Box } from '@mui/material';

import type { GridProps } from '../types';
import { useTheme } from '@hooks/useTheme';

import { gridItemStyles, gridInnerStyles } from '../styles';

export const GridDefault: FC<GridProps> = ({ data, display = 'grid', styles, sx }) => {
   const { theme } = useTheme();

   return (
      <Box
         sx={{
            display,
            gap: 0,
            ...styles,
            ...(gridInnerStyles({ theme }) as object),
            ...((sx ?? {}) as object),
         }}
      >
         {data.map((cell, index) => (
            <Box
               key={index}
               sx={{
                  ...(gridItemStyles({ theme }) as object),
                  gridArea: `${cell.rowStart} / ${cell.colStart} / ${cell.rowEnd} / ${cell.colEnd}`,
                  order: display === 'flex' && cell.order ? cell.order : index,
                  ...cell.styles,
                  ...((cell.sx ?? {}) as object),
               }}
            >
               {cell.content}
            </Box>
         ))}
      </Box>
   );
};
