import React from 'react';
import { Box, styled } from '@mui/material';

// Стиль для ячеек таблицы
const Cell = styled(Box)(({ theme }) => ({
   border: '1px solid #ccc', // Граница для визуализации
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
   height: '100%', // Ячейка заполняет пространство
   backgroundColor: theme.palette.grey[200], // Фон
}));

// Типы для данных и стилей
interface TableMatrixProps {
   data: {
      // Массив данных для таблицы
      content: string | React.ReactNode;
      rowStart: number;
      colStart: number;
      rowEnd: number;
      colEnd: number;
   }[];
   customStyles?: React.CSSProperties; // Дополнительные стили для ячеек
}

export const TableMatrix: React.FC<TableMatrixProps> = ({ data, customStyles = {} }) => {
   return (
      <Box
         sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)', // 3 равных колонки
            gridTemplateRows: 'repeat(3, 1fr)', // 3 равных строки
            gap: 0, // Без отступов
            height: '300px', // Высота всей сетки
            ...customStyles, // Применение внешних стилей
         }}
      >
         {data.map((cell, index) => (
            <Cell
               key={index}
               sx={{
                  gridArea: `${cell.rowStart} / ${cell.colStart} / ${cell.rowEnd} / ${cell.colEnd}`, // Устанавливаем grid-область для ячейки
               }}
            >
               {cell.content} {/* Отображаем содержимое ячейки */}
            </Cell>
         ))}
      </Box>
   );
};
