import { CSSProperties } from 'react';

import { colorsUI } from 'constants/colorsUI';

const { black200, black500, black900 } = colorsUI;

export const sortStyles: CSSProperties = {
   display: 'flex',
   alignItems: 'center',
   gap: '8px',
   color: black900,
   whiteSpace: 'nowrap',
   overflow: 'auto',
};

export const sortStylesDesc: CSSProperties = {
   color: black500,
};

export const sortInnerStyles: CSSProperties = {
   display: 'flex',
   alignItems: 'center',
   gap: '8px',
   justifyContent: 'space-around',
};

export const sortItemsStyles: CSSProperties = {
   color: black200,
   cursor: 'pointer',
};

export const sortItemsActiveStyles: CSSProperties = {
   color: black500,
   fontWeight: 700,
   cursor: 'pointer',
};
