export const hexToRgba = (hex: string, alpha: number = 1): string => {
   // Убираем символ #, если он есть
   let cleanedHex = hex.replace('#', '');

   // Проверяем длину и добавляем недостающие символы для 3-значных hex-значений
   if (cleanedHex.length === 3) {
      cleanedHex = cleanedHex
         .split('')
         .map((x) => x + x)
         .join('');
   }

   // Разбираем на компоненты R, G, B
   const r = parseInt(cleanedHex.substring(0, 2), 16);
   const g = parseInt(cleanedHex.substring(2, 4), 16);
   const b = parseInt(cleanedHex.substring(4, 6), 16);

   // Возвращаем в формате rgba
   return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
