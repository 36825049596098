import React, { FC } from 'react';

import { useTheme } from '@hooks/useTheme';

import {
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Paper,
   Typography,
} from '@mui/material';
import { ChexboxDefault } from 'shared/chexboxs';
import { TableCheckboxProps } from '../types';
import {
   tableStyles,
   tableHeaderStyles,
   tableHeaderCellStyles,
   tableRowCellStyles,
   tableCellStyles,
   tableCheckBoxCellWithCheckboxStyles,
   tableCheckBoxRowCellStyles,
   tableCheckBoxSelectedRowStyles,
   tableCheckBoxHeaderStyles,
   tableCheckBoxCellStyles,
} from '../styles';

import { colorsUI } from 'constants/colorsUI';

const { light100 } = colorsUI;

export const TableCheckbox = <T extends Record<string, any>>({
   columns,
   rows,
   onSelect,
   selected,
   sxRow,
   customId,
}: TableCheckboxProps<T>) => {
   const { theme } = useTheme();

   const handleSelect = (id: number | string) => {
      onSelect((prevSelected) =>
         prevSelected.includes(id)
            ? prevSelected.filter((item) => item !== id)
            : [...prevSelected, id],
      );
   };

   const isSelected = (id: number | string) => selected.includes(id);

   const ID = customId ? customId : 'id';

   return (
      <Paper sx={{ boxShadow: 'none' }}>
         <TableContainer sx={{ ...tableStyles }}>
            <Table sx={{ minWidth: 650 }}>
               <TableHead>
                  <TableRow sx={{ ...tableCheckBoxHeaderStyles({ theme }) }}>
                     <TableCell
                        padding="checkbox"
                        sx={{
                           ...tableCheckBoxCellStyles,
                           ...tableCheckBoxCellWithCheckboxStyles,
                           // padding: 0,
                        }}
                     >
                        <ChexboxDefault
                           checked={selected.length === rows.length}
                           onChange={() => {
                              if (selected.length === rows.length) {
                                 onSelect([]);
                              } else {
                                 onSelect(rows.map((row) => row[ID] as number | string));
                              }
                           }}
                        />
                     </TableCell>
                     {columns.map((column) => (
                        <TableCell
                           sx={{
                              ...tableHeaderCellStyles({ theme }),
                              ...(tableCellStyles as object),
                           }}
                           key={String(column.id)}
                        >
                           <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                              {column.label}
                           </Typography>
                        </TableCell>
                     ))}
                  </TableRow>
               </TableHead>
               <TableBody>
                  {rows.map((row) => (
                     <TableRow
                        key={String(row[ID])}
                        selected={isSelected(row[ID])}
                        sx={{
                           ...tableRowCellStyles,
                           ...(isSelected(row[ID])
                              ? { ...tableCheckBoxSelectedRowStyles }
                              : {}),
                           ...((sxRow ?? {}) as object),
                           transition: 'all .5s',
                           '&:hover': {
                              backgroundColor: light100,
                           },
                        }}
                     >
                        <TableCell
                           padding="checkbox"
                           sx={{
                              ...tableCheckBoxCellStyles,
                           }}
                        >
                           <ChexboxDefault
                              checked={isSelected(row[ID])}
                              onChange={() => handleSelect(row[ID])}
                           />
                        </TableCell>
                        {columns.map((column) => (
                           <TableCell
                              sx={tableCheckBoxRowCellStyles}
                              key={String(column.id)}
                           >
                              {column.renderCell ? (
                                 <>{column.renderCell(row)}</>
                              ) : (
                                 <Typography variant="body1">
                                    {row[column.id as keyof T] || '-'}
                                 </Typography>
                              )}
                           </TableCell>
                        ))}
                     </TableRow>
                  ))}
               </TableBody>
            </Table>
         </TableContainer>
      </Paper>
   );
};
