import React, { FC } from 'react';

import { ButtonDefault, ButtonPagination } from '@shared/buttons';

import { decodeHtmlEntities } from '@utils/html';

import type { PaginationButtonItemProps } from './type';

import { paginationButtonItemButtonStyles } from './style';

import { colorsUI } from '@constants/colorsUI';

const { light800, transperent } = colorsUI;

export const PaginationButtonItem: FC<PaginationButtonItemProps> = ({
   item,
   currentPage,
   onChangePage,
   lastPage,
   nextButtonText = '>',
   prevButtonText = '<',
   disabled,
}) => {
   const isActive = item.page === currentPage;

   if (item.type === 'previous') {
      return (
         <ButtonPagination
            Component={() => (
               <ButtonDefault
                  disabled={currentPage === 1 || disabled}
                  sx={{
                     ...paginationButtonItemButtonStyles({ isActive: !isActive }),
                     height: '100%',
                     lineHeight: '2rem',
                     color: light800,
                     backgroundColor: transperent,
                  }}
                  onClick={(e) => onChangePage(e, currentPage - 1)}
               >
                  {decodeHtmlEntities(prevButtonText)}
               </ButtonDefault>
            )}
            currentPage={item.page as number}
            item={item}
            onClick={(e) => onChangePage(e, currentPage - 1)}
            disabled={currentPage === 1}
            sx={{ ...paginationButtonItemButtonStyles({ isActive }) }}
         ></ButtonPagination>
      );
   }

   if (item.type === 'next') {
      return (
         <ButtonPagination
            Component={() => (
               <ButtonDefault
                  disabled={currentPage === lastPage || disabled}
                  sx={{
                     ...paginationButtonItemButtonStyles({ isActive: !isActive }),
                     height: '100%',
                     lineHeight: '2rem',
                     color: light800,
                     backgroundColor: transperent,
                  }}
                  onClick={(e) => onChangePage(e, currentPage + 1)}
               >
                  {decodeHtmlEntities(nextButtonText)}
               </ButtonDefault>
            )}
            currentPage={item.page as number}
            item={item}
            onClick={(e) => onChangePage(e, currentPage + 1)}
            disabled={currentPage === lastPage || disabled}
            sx={{ ...paginationButtonItemButtonStyles({ isActive }) }}
         ></ButtonPagination>
      );
   }

   return (
      <ButtonPagination
         sx={{ ...paginationButtonItemButtonStyles({ isActive }) }}
         currentPage={item.page as number}
         item={item}
         onClick={(e) => onChangePage(e, item.page as number)}
         disabled={disabled}
      />
   );
};
