import { colorsUI } from '@constants/colorsUI';
import { SxProps, Theme } from '@mui/material';

const { light } = colorsUI;

export const gridInnerStyles = ({ theme }: { theme: Theme }): SxProps<Theme> => ({
   [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
   },
});

export const gridItemStyles = ({ theme }: { theme: Theme }): SxProps<Theme> => ({
   border: '1px solid #ccc',
   padding: '12px 16px',
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
   height: '100%',
   backgroundColor: light,
});
