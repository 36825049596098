import React, { FC, MouseEventHandler, ReactNode } from 'react';
import FormControl from '@mui/material/FormControl';
import { SelectChangeEvent, Typography } from '@mui/material';
import ArrowSelectIcon from '../icons/arrowSelect';
import type { SelectProps } from '../types';
import { CustomSelect, CustomMenuItem, CustomOutlinedInput } from '../styles';
import { SelectOption } from '../types/type';

export const SelectDefault: FC<SelectProps> = ({
   value = 'value',
   onChange,
   disable = false,
   arrValues,
   placeholder = 'placeholder',
   isDefaultValue = false,
   itemHeight = 48,
   itemPaddingTop = 8,
   styles,
   labelId = 'custom-select-label',
   id = 'custom-select',
   label,
   labelStyles,
   labelVariant = 'body2',
   sx,
   error,
   onClose,
   onOpen,
   onClick,
}) => {
   const MenuProps = {
      PaperProps: {
         style: {
            maxHeight: itemHeight * 4.5 + itemPaddingTop,
            width: 250,
         },
      },
   };

   const handleChange = (event: SelectChangeEvent<unknown>, child: ReactNode) => {
      onChange(event as SelectChangeEvent<string>);
   };

   return (
      <FormControl sx={{ ...styles, ...(sx ?? {}) }} error={error}>
         <CustomSelect
            labelId={labelId}
            id={id}
            value={value}
            onChange={handleChange}
            input={<CustomOutlinedInput disabled={disable} />}
            MenuProps={MenuProps}
            renderValue={(selected) => {
               const selectedOption = (arrValues as SelectOption[]).find(
                  (opt) => typeof opt === 'object' && opt.value === selected,
               );

               return selectedOption
                  ? selectedOption.label
                  : isDefaultValue
                    ? value
                    : placeholder;
            }}
            IconComponent={ArrowSelectIcon}
            disabled={disable}
            displayEmpty
            onClick={() => onClick?.()}
            onOpen={() => onOpen?.()}
            onClose={() => onClose?.()}
         >
            {arrValues.map((option: SelectOption | string) => {
               if (typeof option === 'object' && 'label' in option) {
                  return (
                     <CustomMenuItem
                        key={option.value}
                        value={option.value}
                        disabled={disable}
                     >
                        {option.label}
                     </CustomMenuItem>
                  );
               }
               return (
                  <CustomMenuItem key={option} value={option} disabled={disable}>
                     {option}
                  </CustomMenuItem>
               );
            })}
         </CustomSelect>
         {label && (
            <Typography variant={labelVariant} style={labelStyles}>
               {label}
            </Typography>
         )}
      </FormControl>
   );
};
