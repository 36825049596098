import { useProjectBySlugQuery } from 'app/dashboard/store/api/projectApi';

import type { IErrorResponse } from '@projectTypes/apiTypes';

export const useProjectBySlugData = ({ slug }: { slug?: string }) => {
   const { data, isError, isLoading, isSuccess, isFetching, error, refetch } =
      useProjectBySlugQuery({ slug });

   return {
      slug,
      data,
      isError,
      isLoading,
      isSuccess,
      isFetching,
      refetch,
      error: error as IErrorResponse,
   };
};
