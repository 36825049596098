import React, { FC, ElementType, ReactNode } from 'react';

import { useTheme } from '@hooks/useTheme';

import { Box } from '@mui/material';

import type { SxProps, Theme } from '@mui/system';
import type { ColumnWrapperProps } from '../types';
import { columnStyles, columnWrapperStyles } from '../styles';

export const ColumnWrapper: FC<ColumnWrapperProps> = ({
   gap = '24px',
   gapMiddle = '22px',
   gapMobile = '19px',
   children,
   styles,
   maxWidth = '750px',
   component: Component = Box,
   sx,
   sxInner,
   ...other
}) => {
   const { theme } = useTheme();

   const columnDashboardStyles = columnStyles({
      theme,
      gap,
      gapMiddle,
      gapMobile,
      customStyles: styles,
   });

   return (
      <Box sx={{ ...columnWrapperStyles, ...(sxInner ?? {}) }}>
         <Box
            component={Component}
            sx={{
               ...columnDashboardStyles,
               maxWidth: maxWidth,
               ...((sx ?? {}) as object),
            }}
            {...other}
         >
            {children}
         </Box>
      </Box>
   );
};
