import React, { useRef, useState } from 'react';

import { useManager } from '@hooks/hooksApi/manager/useManager';

import { ManagersNone } from '../managersNone';
import { ManagersData } from '../managersData';
import { Box, Typography } from '@mui/material';
import { ColumnDashboard } from '@shared/column';
import { ProfileHeader } from '@componentsDashBoard/profileHeader';
import { LinkDefault } from '@shared/links';
import { LoaderDefault } from '@shared/loader';

import { ReactComponent as PlusIcon } from '@assets/icons/plus.svg';

import type { IManagerResponse } from '@projectTypes/manager';

import { managersContentStyles, managersNoneButtonStyles } from './styles';
import styles from './styles/styles.module.css';
import { ErrorMessage } from '@shared/errorMessage';

const { menegersInner } = styles;

export const Menegers = () => {
   const {
      dataManager,
      errorManager,
      isErrorManager,
      isLoadingManager,
      isFetchinggManager,
   } = useManager();

   return (
      <ColumnDashboard styles={menegersInner}>
         <ProfileHeader title="Мои менеджеры">
            {!isErrorManager && (
               <LinkDefault
                  href="/managers/create-managers"
                  sx={managersNoneButtonStyles as object}
                  Icon={PlusIcon}
                  label="Добавить менеджера"
               />
            )}
         </ProfileHeader>

         {isErrorManager ? (
            <ErrorMessage message={errorManager.data.data.message} />
         ) : (
            <Box sx={managersContentStyles({ isNoneMenegers: isErrorManager })}>
               {isLoadingManager ? (
                  <LoaderDefault stylesInner={{ justifyContent: 'center' }} />
               ) : dataManager?.data.length === 0 ? (
                  <ManagersNone />
               ) : (
                  <ManagersData
                     dataManager={dataManager as IManagerResponse}
                     isFetching={isFetchinggManager}
                     isLoading={isLoadingManager}
                  />
               )}
            </Box>
         )}
      </ColumnDashboard>
   );
};
