import { configureStore } from '@reduxjs/toolkit';

import { setupListeners } from '@reduxjs/toolkit/query';

import authSlice from './slices/auth/authSlice';
import projectsSlice from './slices/projects/projectsSlice';
import profileSlice from './slices/profile/profileSlice';
import projectsCustomFieldsSlice from './slices/projects/projectsCustomFieldsSlice';
import leadSlice from './slices/projects/projectsCustomFieldsSlice';

import { authApi } from './api/authApi';
import { profileApi } from './api/profileApi';
import { projectApi } from './api/projectApi';
import { treeApi } from './api/treeApi';
import { runtimeApi } from './api/runtimeApi';
import { leadApi } from './api/leadApi';
import { runtimeLogApi } from './api/runtimeLogApi';
import { managerApi } from './api/managerApi';

const store = configureStore({
   reducer: {
      [authApi.reducerPath]: authApi.reducer,
      [profileApi.reducerPath]: profileApi.reducer,
      [projectApi.reducerPath]: projectApi.reducer,
      [treeApi.reducerPath]: treeApi.reducer,
      [runtimeApi.reducerPath]: runtimeApi.reducer,
      [managerApi.reducerPath]: managerApi.reducer,
      [leadApi.reducerPath]: leadApi.reducer,
      [runtimeLogApi.reducerPath]: runtimeLogApi.reducer,
      auth: authSlice,
      projects: projectsSlice,
      profile: profileSlice,
      projectsCustomFields: projectsCustomFieldsSlice,
      leadSlice: leadSlice,
   },
   middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
         authApi.middleware,
         profileApi.middleware,
         projectApi.middleware,
         treeApi.middleware,
         runtimeApi.middleware,
         leadApi.middleware,
         managerApi.middleware,
         runtimeLogApi.middleware,
      ),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
