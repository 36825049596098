import type { handleCreateProjectsFormErrorParams } from './type';
import type { FormCreateProjectErrorsType } from '../../types';

export const handleCreateProjectsFormError = ({
   formData,
   errorsMessage,
   setErrors,
}: handleCreateProjectsFormErrorParams): boolean => {
   const errors: Partial<FormCreateProjectErrorsType> = {};

   const { scenario_type, name, business_area, contact_base_type } = formData;

   if (!scenario_type) {
      errors.scenario_type = errorsMessage.scenario_type;
   }
   if (!business_area) {
      errors.business_area = errorsMessage.business_area;
   }

   if (!contact_base_type) {
      errors.contact_base_type = errorsMessage.contact_base_type;
   }
   if (name.length < 10) {
      errors.name = errorsMessage.name;
   }

   setErrors(errors as FormCreateProjectErrorsType);
   return Object.keys(errors).length !== 0;
};
