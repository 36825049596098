export const removeFalsyValuesInObject = (
   obj: Record<string, any>,
): Record<string, any> => {
   const newObj: Record<string, any> = {};

   for (const key in obj) {
      const value = obj[key];

      if (value) {
         newObj[key] = value;
      }
   }

   return newObj;
};
