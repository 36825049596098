import React, { FC } from 'react';

import { useProfileDelete } from '@hooks/hooksApi';
import { useToggleModal } from '@hooks/hooksUI/useToggleModal';
import { useToggleModal as useToggleAlert } from '@hooks/hooksUI/useToggleModal';

import { AlertSnackbar } from '@shared/alert';
import { ButtonAction } from '@shared/buttons';
import { ProfileHeader } from '@componentsDashBoard/profileHeader';
import { ColumnDashboard } from '@shared/column';
import { AccountData } from '../accountData';
import { ModalDelete } from '@shared/modals';

import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';

import { accountHeaderButtonStyles } from './style';

export const Account: FC = () => {
   const { deleteProfileData, isError, error, isLoading, isSuccess } = useProfileDelete();
   const { isOpen, toggle } = useToggleModal();

   const { isOpen: isOpenAlert, setIsOpen: setIsOpenAlert } = useToggleAlert();

   const onClickDelete = () => {
      alert('delete');
      deleteProfileData();
   };

   return (
      <ColumnDashboard>
         <AlertSnackbar open={isOpenAlert} setOpen={setIsOpenAlert} message="message" />
         <ProfileHeader title="Аккаунт">
            <ButtonAction
               onClick={toggle}
               sx={accountHeaderButtonStyles()}
               Icon={DeleteIcon}
            >
               Удалить аккаунт
            </ButtonAction>
         </ProfileHeader>

         <AccountData />
         <ModalDelete
            title="Удаление аккаунта"
            text="Вы уверены, что хотите удалить аккаунт? В последствии восстановить его нельзя"
            onDelete={onClickDelete}
            open={isOpen}
            onClose={toggle}
         />
      </ColumnDashboard>
   );
};
