import { Dispatch, FormEvent, SetStateAction } from 'react';
import { validateClientAddForm } from '../validateForm';

import type { onSubmitClientsAddFormParams } from './type';

export const onSubmitClientsAddForm = async ({
   e,
   onClose,
   setErrors,
   data,
   createLead,
   toggle,
   setCountryCode,
   setName,
   setPhone,
   setSource,
   refetch,
}: onSubmitClientsAddFormParams) => {
   e.preventDefault();

   const { name, phone, phoneCode, source } = data;

   const { success, errors } = validateClientAddForm({
      name,
      phone,
      source,
   });

   if (success) {
      try {
         await createLead({
            name,
            phone_code: phoneCode,
            phone_number: phone,
            source,
         });
         refetch();
         setErrors({ name: '', phone: '', source: '' });
         setCountryCode('');
         setName('');
         setPhone('');
         setSource('');
         onClose();
      } catch (err) {
         console.error('errr');
         toggle();
      }
   } else {
      setErrors(errors);
   }
};
