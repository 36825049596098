import React, { useState, MouseEvent, useMemo, FC } from 'react';

import { useProjectData } from '@hooks/hooksApi/project/useProjectData';
import { useTheme } from '@hooks/useTheme';

import { Box, Theme, useMediaQuery } from '@mui/material';
import { LoaderDefault } from '@shared/loader';
import { TableDefault, TableMobile } from '@shared/tables';
import { TableActionsButtonMoreOptions } from '@components/tableActions';
import { ButtonAction } from '@shared/buttons';

import { IProjectTable } from '@projectTypes/projects';
import { ColumnTable } from '@shared/tables/types';
import { LinkDefault } from '@shared/links';
import { PopupDefault } from '@shared/popups';
import { ListPopupActionContents } from '@shared/lists';
import { FilterButton } from '@shared/filters';

import { ReactComponent as IconPerson } from '@assets/icons/personIcon.svg';
import { ReactComponent as IconMail } from '@assets/icons/mail.svg';
import { ReactComponent as IconAnalytics } from '@assets/icons/analyticsPopupIcon.svg';
import { ReactComponent as IconLogs } from '@assets/icons/logsIcon.svg';
import { ReactComponent as IconDelete } from '@assets/icons/deleteKrest.svg';
import { ReactComponent as IconEdit } from '@assets/icons/edit.svg';
import { ReactComponent as IconPlus } from '@assets/icons/plus.svg';

import { handleClickPopup } from '@utils/handleClickPopup';

import {
   accountProjectsTableLinkStyles,
   accountProjectsButtonActionStyles,
   accountProjectsStyles,
   accountProjectsTableItemDraftTextStyles,
} from './style';

const arrValuesButton = ['все проекты', 'Личные проекты', 'Я менеджер'];

export const AccountProjects = () => {
   const [popupIsOpen, setPopupIsOpen] = useState(false);
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const [rowState, setRowState] = useState<IProjectTable | null>(null);

   const [valueFilterButton, setValueFilterButton] = useState<string | number>(
      arrValuesButton[0],
   );

   const { projectData, projectIsFetching, projectIsLoading, projectIsError } =
      useProjectData();

   const { theme } = useTheme();

   console.log(projectData?.data);

   const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

   const handleClosePopup = () => {
      setAnchorEl(null);
      setPopupIsOpen(false);
   };

   const handleOpenDeleteModal = () => {
      setAnchorEl(null);
      setPopupIsOpen(false);
   };
   const handleClickEdit = () => {
      console.log(rowState);
   };

   const popupActionContentItems = useMemo(
      () => [
         {
            title: 'Перейти в профиль',
            onClick: () => alert('Перейти в профиль'),
            Icon: IconPerson,
         },
         {
            title: 'Отправить приглашение',
            onClick: () => alert('Отправить приглашение'),
            Icon: IconMail,
            disable: true,
         },
         {
            title: 'Аналитика',
            onClick: () => alert('Аналитика'),
            Icon: IconAnalytics,
         },
         {
            title: 'Логи',
            onClick: () => alert('Логи'),
            Icon: IconLogs,
         },
         {
            title: 'Удалить',
            onClick: handleOpenDeleteModal,
            Icon: IconDelete,
            error: true,
         },
         {
            title: 'редактировать',
            onClick: handleClickEdit,
            Icon: IconEdit,
            href: `constructor/${rowState?.slug}`,
         },
      ],
      [handleClickPopup, rowState],
   );

   if (projectIsLoading) return <LoaderDefault />;

   if (projectIsError) return <p>ошибка!</p>;

   const columns: ColumnTable<IProjectTable>[] = [
      {
         id: 'name',
         label: 'Наименование скрипта',
         renderCell: (row: IProjectTable) => (
            <>
               {row.name}
               {row.is_draft && (
                  <Box
                     sx={accountProjectsTableItemDraftTextStyles({ theme })}
                     component="span"
                  >
                     (черновик)
                  </Box>
               )}
            </>
         ),
      },
      {
         id: 'role',
         label: 'Тип проекта',
         renderCell: (row: IProjectTable) =>
            row.role === 'Владелец' ? 'Личный ' : 'Я - менеджер',
      },
      {
         id: 'actions',
         label: 'Действия',
         renderCell: (row: IProjectTable) =>
            row.role === 'Владелец' ? (
               <TableActionsButtonMoreOptions
                  text="Редактировать"
                  href={`/visual-script/${row.slug}`}
                  onClick={(e) =>
                     handleClickPopup({
                        event: e as MouseEvent<HTMLElement>,
                        row,
                        setAnchorEl,
                        setPopupIsOpen,
                        setRowState,
                     })
                  }
               />
            ) : (
               <LinkDefault sx={accountProjectsTableLinkStyles} href={`${row.slug}`}>
                  Запустить
               </LinkDefault>
            ),
      },
   ];

   const onFilterButtonChange = (value: string | number) => {
      setValueFilterButton(value);
   };

   return (
      <Box sx={accountProjectsStyles({ theme })}>
         <FilterButton
            value={valueFilterButton}
            onFilterChange={onFilterButtonChange}
            arrValues={arrValuesButton}
         />

         {isMobile ? (
            <TableMobile columns={columns} rows={projectData?.data as IProjectTable[]} />
         ) : (
            <TableDefault
               sxContainer={{ maxWidth: 'fit-content' }}
               columns={columns}
               rows={projectData?.data as IProjectTable[]}
            />
         )}

         <PopupDefault
            styles={{ padding: 0 }}
            handleClose={handleClosePopup}
            open={popupIsOpen}
            anchorEl={anchorEl}
         >
            <ListPopupActionContents
               styles={{ padding: 0 }}
               items={popupActionContentItems}
            />
         </PopupDefault>
         <ButtonAction
            Icon={IconPlus}
            sx={{ ...accountProjectsButtonActionStyles({ theme }) }}
         >
            Создать проект
         </ButtonAction>
      </Box>
   );
};
