import React, { FC, FormEvent, useState } from 'react';

import { useTheme } from '@hooks/useTheme';
import { useLeadCreate } from '@hooks/hooksApi';
import { useToggleModal as useToggleAlert } from '@hooks/hooksUI/useToggleModal';

import { ModalDefault } from '@shared/modals';
import { ColumnWrapper } from '@shared/column';
import { InputDefault, InputPhone } from '@shared/inputs';
import { Box, Typography } from '@mui/material';
import { AlertSnackbar } from '@shared/alert';
import { ButtonDefault } from '@shared/buttons';

import handleInput from '@utils/handleInput';

import { onSubmitClientsAddForm } from './utils';

import type { ClientsAddFormErrorsType, ModalClientAddProps } from './types';

import imgAddPath from '@assets/images/add.png';

import {
   modalStyles,
   modalClientAddStyles,
   modalClientsAddInputBox,
   modalClientAddImgStyles,
   modalClientsAddInputsTitle,
   modalClientAddLabelStyles,
   modalClientAddInputStyles,
   modalClientAddButtonBoxStyles,
   modalClientsAddButtonStyles,
   modalClientAddCloseIconStyles,
} from './style';

import { ReactComponent as IconKrest } from '@assets/icons/deleteKrest.svg';
import { removeCountryCode } from '@utils/phone';
import { ErrorMessage } from '@shared/errorMessage';

export const ModalClientAdd: FC<ModalClientAddProps> = ({ open, onClose, refetch }) => {
   const [name, setName] = useState<string>('');
   const [source, setSource] = useState<string>('');
   const [phone, setPhone] = useState('');
   const [countryCode, setCountryCode] = useState('');

   const [errors, setErrors] = useState<ClientsAddFormErrorsType>({
      name: '',
      phone: '',
      source: '',
   });

   const { createLeadData, error, isError, isLoading } = useLeadCreate();

   const { isOpen: isOpenAlert, setIsOpen: setIsOpenAlert, toggle } = useToggleAlert();

   const { theme } = useTheme();

   const onSubmitForm = async (e: FormEvent) => {
      const data = {
         name,
         phone: removeCountryCode({ code: countryCode, phone }),
         phoneCode: countryCode,
         source,
      };
      await onSubmitClientsAddForm({
         createLead: createLeadData,
         e,
         onClose,
         setErrors,
         data,
         toggle,
         setName,
         setSource,
         setPhone,
         setCountryCode,
         refetch,
      });
   };

   return (
      <ModalDefault
         stylesModalContent={{ ...(modalStyles(theme) as object) }}
         open={open}
         handleClose={onClose}
      >
         <AlertSnackbar
            duration={3000}
            severity={isError ? 'error' : 'success'}
            open={isOpenAlert}
            setOpen={setIsOpenAlert}
            message={isError ? 'ошибка!' : 'success'}
         />
         <Box onClick={onClose} sx={{ ...modalClientAddCloseIconStyles({ theme }) }}>
            <IconKrest />
         </Box>
         <ColumnWrapper
            component="form"
            onSubmit={onSubmitForm}
            sx={{ ...modalClientAddStyles }}
            maxWidth="464px"
         >
            <Box
               sx={modalClientAddImgStyles}
               component="img"
               src={imgAddPath}
               alt="Добавить клиента"
            />
            <Typography variant="h2" sx={modalClientsAddInputsTitle(theme)}>
               Добавить клиента
            </Typography>
            <Box sx={modalClientsAddInputBox}>
               <InputDefault
                  required
                  value={name}
                  onChange={(e) => handleInput(e, setName)}
                  placeholder="ФИО Клиента*"
                  label="Можно использовать, как константу в редакторе скриптов"
                  labelVariant="overline"
                  stylesWrapper={modalClientAddInputStyles}
                  labelStyles={modalClientAddLabelStyles}
                  error={errors.name}
               />
               <InputDefault
                  required
                  value={source}
                  onChange={(e) => handleInput(e, setSource)}
                  placeholder="Источник*"
                  label="Укажите источник"
                  labelVariant="overline"
                  stylesWrapper={modalClientAddInputStyles}
                  labelStyles={modalClientAddLabelStyles}
                  error={errors.source}
               />

               <InputPhone
                  placeholder="+7-777-777-77-77"
                  sxWrapper={{ width: '100%' }}
                  phone={phone}
                  setPhone={setPhone}
                  setCountry={setCountryCode}
                  error={errors?.phone && errors?.phone}
               />
               {isError && <ErrorMessage message={error.data.data.message} />}
            </Box>

            <Box sx={modalClientAddButtonBoxStyles}>
               <ButtonDefault
                  disabled={isLoading}
                  onClick={onSubmitForm}
                  type="submit"
                  label="Добавить контакт"
                  sx={modalClientsAddButtonStyles(theme) as object}
               />
               <ButtonDefault
                  onClick={onClose}
                  disabled={isLoading}
                  label="Выйти без сохранения"
               />
            </Box>
         </ColumnWrapper>
      </ModalDefault>
   );
};
