import React, { FC } from 'react';

import Backdrop from '@mui/material/Backdrop';
import { LoaderDefault } from '@shared/loader';

import type { OverlayLoadingProps } from '../types';

import { colorsUI } from '@constants/colorsUI';
import { hexToRgba } from '@utils/color';
import { Box } from '@mui/material';

const { black600, light900 } = colorsUI;

export const OverlayLoading: FC<OverlayLoadingProps> = ({
   isOpen = false,
   colorLoader = light900,
   background = black600,
   opacity = 0.9,
   children,
   styles,
   sx,
   stylesInner,
   sxInner,
}) => {
   const opacityValue = typeof opacity === 'string' ? parseFloat(opacity) : opacity;

   const res = hexToRgba(background, opacity);

   return (
      <Box
         style={{
            position: 'relative',
            display: 'inline-block',
            ...((sxInner ?? {}) as object),
            ...stylesInner,
         }}
      >
         <Backdrop
            sx={{
               position: 'absolute',
               color: '#fff',
               zIndex: 2,
               backgroundColor: res,
               borderRadius: '8px',
               opacity: opacityValue,
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
               ...styles,
               ...((sx ?? {}) as object),
            }}
            open={isOpen}
         >
            <LoaderDefault color={colorLoader} />
         </Backdrop>
         {children}
      </Box>
   );
};
