import React, { FC } from 'react';

import { HistoryScriptHeader } from '../historyScriptHeader';
import { HistoryScriptInner } from '../historyScriptInner';
import { ColumnDashboard } from '@shared/column';

import type { HistoryScriptProps } from './type';

export const HistoryScript: FC<HistoryScriptProps> = ({ data, isSuccess }) => {
   const { history, runtime, runtime_finished_at, runtime_started_at, status } =
      data.data;

   const historyArr = isSuccess ? Object.entries(history || {}) : [];

   return (
      <ColumnDashboard gap="32px">
         <HistoryScriptHeader
            status={status}
            uuid={runtime}
            dataStart={runtime_started_at}
            dataFinish={runtime_finished_at}
         />

         <HistoryScriptInner dataStarted={runtime_finished_at} history={historyArr} />
      </ColumnDashboard>
   );
};
