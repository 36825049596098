import useAppDispatch from 'app/dashboard/store/hooks/useAppDispatch';
import { useEffect } from 'react';
import { useLeadQuery } from 'app/dashboard/store/api/leadApi';
import type { useLeadDataParams } from './type';

import { setLeadData } from 'app/dashboard/store/slices/lead/leadSlice';
import type { IErrorResponse } from '@projectTypes/apiTypes';

export const useLeadData = ({ page = 1 }: useLeadDataParams) => {
   const dispatch = useAppDispatch();

   const {
      data: dataLead,
      error,
      isLoading: isLoadingLead,
      isError: isErrorLead,
      isSuccess: isSuccessLead,
      refetch: refetchLead,
      isFetching: isFetchingLead,
   } = useLeadQuery({ page });

   useEffect(() => {
      if (isSuccessLead) {
         setLeadData(dataLead);
      }
   }, [dispatch, dataLead, isLoadingLead, isErrorLead]);

   return {
      dataLead,
      isLoadingLead,
      isErrorLead,
      error: error as IErrorResponse,
      refetchLead,
      isFetchingLead,
   };
};
