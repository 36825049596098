import { useRuntimeStartQuery } from 'app/dashboard/store/api/runtimeApi';
import type { IErrorResponse } from '@projectTypes/apiTypes';

export const useRuntimeData = (slug?: string) => {
   const {
      data: runtimeStartData,
      isLoading: runtimeStartIsLoading,
      isError: runtimeStartIsError,
      isSuccess: runtimeStartIsSuccess,
      isFetching: runtimeStartIsFetching,
      error: runtimeStartErrorMessage,
   } = useRuntimeStartQuery({ slug }, { skip: !slug });

   return {
      runtimeStartData,
      runtimeStartIsLoading,
      runtimeStartDataLead: runtimeStartData?.data.lead,
      isRuntimeStartDataLead: !!runtimeStartData?.data.lead, // с учетом того что если данных лида нет то передается null
      runtimeStartIsError,
      runtimeStartIsSuccess,
      runtimeStartIsFetching,
      runtimeStartErrorMessage: runtimeStartErrorMessage as IErrorResponse,
   };
};
