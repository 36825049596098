import React, { useState, MouseEvent, FC } from 'react';

import { useTheme } from '@hooks/useTheme';

import { FilterButtonMore } from '@shared/filters/filterButtonMore';
import { PopupDefault } from '@shared/popups';
import { Box } from '@mui/material';
import { ListSelect } from '@shared/lists';

import type { SortOptionType, SortsPopUpProps } from './type';

import { sortsPopUpListStyle } from './style';

export const SortPopUp: FC<SortsPopUpProps> = ({ sorts, value, setValue }) => {
   const [popupContent, setPopupContent] = useState<SortOptionType[]>([]);
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const [popupIsOpen, setPopupIsOpen] = useState(false);
   const [activeSort, setActiveSort] = useState<string>('');

   const theme = useTheme();

   const handleClickPopup = (
      event: MouseEvent<HTMLElement>,
      content: SortOptionType[],
      sortType: string,
   ) => {
      setAnchorEl(event.currentTarget);
      setPopupContent(content);
      setActiveSort(sortType as keyof typeof value);
      setPopupIsOpen(true);
   };

   const handleClosePopup = () => {
      setAnchorEl(null);
      setPopupIsOpen(false);
   };

   const handleSelectChange = (index: number) => {
      if (activeSort) {
         console.log(activeSort);
         setValue((prev) => ({
            ...prev,
            [activeSort]: index,
         }));
      }
      handleClosePopup();
   };

   return (
      <Box>
         <PopupDefault
            handleClose={handleClosePopup}
            open={popupIsOpen}
            anchorEl={anchorEl}
         >
            <ListSelect
               handleSelectChange={handleSelectChange}
               select={value[activeSort] as number}
               items={popupContent}
            />
         </PopupDefault>
         <Box sx={{ ...sortsPopUpListStyle(theme) }}>
            {sorts!.map((el) => (
               <FilterButtonMore
                  key={el.name}
                  filterType={el.name}
                  handleClickPopup={handleClickPopup}
                  content={el.options}
                  text={el.text}
                  Icon={el.Icon}
               />
            ))}
         </Box>
      </Box>
   );
};
