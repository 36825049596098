import { SxProps, Theme } from '@mui/material';

import getActiveColor from '@utils/getActiveColor';

import { colorsUI } from 'constants/colorsUI';

const { light200, light500, black800, transperent } = colorsUI;

type SortButtonsActiveStylesParams = {
   isActive: boolean;
};

export const SortButtonsActiveStyles = ({
   isActive,
}: SortButtonsActiveStylesParams): SxProps<Theme> => ({
   background: isActive ? light200 : transperent,
   color: isActive ? black800 : light500,
});
