import { useManagerAttachProjectMutation } from 'app/dashboard/store/api/managerApi';

import type { IErrorResponseSimplified } from '@projectTypes/apiTypes';

export const useManagerAttachProjects = () => {
   const [attachProject, { isError, error, isLoading, isSuccess }] =
      useManagerAttachProjectMutation();

   const attachProjectData = async (id: string) => {
      try {
         const result = await attachProject({
            id,
         }).unwrap();
         console.log(result);
         return result;
      } catch (err) {
         const customError = err as IErrorResponseSimplified;
         console.log(customError);

         if ('data' in customError) {
            console.error('Ошибка API:', customError.data.message);
            throw new Error(customError.data.message);
         } else {
            console.error('Системная ошибка:', err);
            throw new Error('Неизвестная ошибка');
         }
      }
   };

   return {
      attachProjectData,
      isError,
      error: error as IErrorResponseSimplified,
      isLoading,
      isSuccess,
   };
};
