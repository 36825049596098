import { SxProps, Theme } from '@mui/material/styles';

import { colorsUI } from 'constants/colorsUI';

const { light400 } = colorsUI;

export const inner = (theme: Theme): SxProps<Theme> => ({
   display: 'flex',
   alignItems: 'center',
   gap: '24px',
   flexWrap: 'wrap',
   [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
   },
});

export const SortButtonstyles = (theme: Theme): SxProps<Theme> => ({
   border: `1px solid ${light400}`,
   [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
   },
});

export const filterBoxStyles = (theme: Theme): SxProps<Theme> => ({
   display: 'flex',
   alignItems: 'center',
   gap: '16px',
   flexWrap: 'wrap',
   [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
   },
});
