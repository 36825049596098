import React, { FC, MouseEvent } from 'react';

import { useTheme } from '@hooks/useTheme';

import { TableActionsButtonMoreOptions } from '@components/tableActions';

import type { FilterButtonMoreProps } from '../types';

import { filterButtonMoreStyle, filterButtonMoreTextStyle } from '../styles';

export const FilterButtonMore: FC<FilterButtonMoreProps> = ({
   text = 'По виду скрипта',
   content,
   filterType,
   handleClickPopup,
   Icon,
}) => {
   const theme = useTheme();

   return (
      <TableActionsButtonMoreOptions
         sxInner={{ ...filterButtonMoreStyle(theme) }}
         sx={{ ...filterButtonMoreTextStyle() }}
         text={text}
         Icon={Icon}
         onClick={(e) =>
            handleClickPopup(e as MouseEvent<HTMLButtonElement>, content, filterType)
         }
      />
   );
};
