import React, { FC, ChangeEventHandler, useState, ChangeEvent, useEffect } from 'react';

import { InputDefault, InputPhone } from '@shared/inputs';
import { ButtonIcon } from '@shared/buttons';
import { Box } from '@mui/material';

import type { VisualScriptFormIsNoneTextFieldProps } from './type';

import { visualScriptFormIsNoneTextFieldLabelStyles } from './style';
import { CountryData } from 'react-phone-input-material-ui';

export const VisualScriptFormIsNoneTextField: FC<
   VisualScriptFormIsNoneTextFieldProps
> = ({ placeholder, slug, type, required, onChange, error }) => {
   const [value, setValue] = useState('');

   const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      setValue(inputValue);
      onChange?.(slug, inputValue);
   };

   const handlePhoneChange = (value: string, country: CountryData) => {
      onChange?.(slug, { countryCode: country.dialCode, phoneNumber: value });
   };

   return (
      <Box>
         {type === 'tel' ? (
            <InputPhone
               sxLabel={{ ...visualScriptFormIsNoneTextFieldLabelStyles() }}
               stylesWrapper={{ width: '100%', display: 'flex', flexDirection: 'column' }}
               stylesLabel={{ marginTop: '10px' }}
               phone={value}
               setPhone={setValue}
               onChange={handlePhoneChange}
               labelVariant="caption"
               error={required && error ? error : ''}
            />
         ) : (
            <InputDefault
               sxLabel={{ ...visualScriptFormIsNoneTextFieldLabelStyles() }}
               stylesWrapper={{ width: '100%', display: 'flex', flexDirection: 'column' }}
               required={required}
               type={type === 'int' ? 'number' : type}
               placeholder={placeholder}
               value={value}
               onChange={handleInputChange}
               labelVariant="caption"
               error={required && error ? error : ''}
            />
         )}
      </Box>
   );
};
