import React, { FC } from 'react';

import { useToggleModal as useToggle } from '@hooks/hooksUI';
import { useTheme } from '@hooks/useTheme';

import { Box, Typography } from '@mui/material';
import { HistoryScriptItem } from '../historyScriptItem';
import { ButtonAction } from '@shared/buttons';

import { ReactComponent as IconPlus } from '@assets/icons/plus.svg';

import type { HistoryScriptInnerProps } from './type';
import type { IHistory } from '@projectTypes/entities';

import { extractDate } from '@utils/date';

import {
   historyScriptInnerStyles,
   historyScriptInnerButtonIsVisibleIconStyles,
   historyScriptInnerButtonIsVisibleStyles,
   historyScriptInnerHeadStyles,
   historyScriptDateStartedStyles,
} from './style';

export const HistoryScriptInner: FC<HistoryScriptInnerProps> = ({
   history,
   countVisible = 4,
   dataStarted,
}) => {
   const { theme } = useTheme();

   const { isOpen: isVisible, toggle: toggleIsVisible } = useToggle();

   const displayedHistory = isVisible ? history : history.slice(0, countVisible);

   const showButtonIsVisible = history.length > countVisible;

   return (
      <Box sx={{ ...historyScriptInnerStyles({ theme }) }}>
         <Typography sx={{ ...historyScriptInnerHeadStyles({ theme }) }} variant="h1">
            История
         </Typography>
         <Typography sx={historyScriptDateStartedStyles}>
            {extractDate(dataStarted)}
         </Typography>

         {displayedHistory.map(([key, value]: [string, IHistory]) => (
            <HistoryScriptItem key={key} historyMessage={value} />
         ))}
         {showButtonIsVisible && (
            <ButtonAction
               sx={{ ...historyScriptInnerButtonIsVisibleStyles({ theme }) }}
               iconStyles={{
                  ...(historyScriptInnerButtonIsVisibleIconStyles({
                     isVisible,
                  }) as object),
               }}
               Icon={IconPlus}
               onClick={toggleIsVisible}
            >
               {isVisible ? 'Скрыть' : 'Смотреть еще'}
            </ButtonAction>
         )}
      </Box>
   );
};
