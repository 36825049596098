export const businessAreaLabels: Record<string, string> = {
   investments: 'Инвестиции, Биржа, Криптовалюта',
   finance: 'Финансы, Банки, Кредиты',
   franchise: 'Франшиза, Бизнес, Партнерство',
   realEstate: 'Недвижимость, Ипотека, Аренда',
   law: 'Юриспруденция, Банкротство, Адвокаты',
   taxes: 'Налоги, Бухгалтерия, Онлайн-касса',
   hr: 'HR, Рекрутинг, Хедхантинг',
   advertising: 'Реклама, Маркетинг, PR',
   it: 'Сайты, IT разработка, SEO',
   leads: 'Лиды, CPA, Арбитраж',
   software: 'Софт, CRM, Облачные сервисы',
   telecom: 'Телеком, Коммуникации, Связь',
   delivery: 'Курьеры, Пункты выдачи, Доставка',
   logistics: 'Логистика, Перевозки, Грузы',
   health: 'Здоровье, Медицина, Анализы',
   beauty: 'Красота, Косметология, Стиль',
   sport: 'Спорт, Тренировки, Диеты',
   fashion: 'Одежда, Украшения, Аксессуары',
   marketplaces: 'Маркетплейсы, Дропшиппинг, Ритейл',
   tech: 'Техника, Ремонт, Настройка',
   household: 'Бытовые услуги, Мастер на час',
   autoservice: 'Автосервис, Детейлинг, Запчасти',
   tourism: 'Туризм, Путешествие, Билеты',
   catering: 'Рестораны, Готовая еда, Кейтеринг',
   construction: 'Ремонт, Строительство, Материалы',
   furniture: 'Мебель, Декор, Интерьер',
   outsourcing: 'Аутсорсинг, Аутстаффинг, Клининг',
   education: 'Обучение, Курсы, Тренинги',
   mentoring: 'Наставничество, Менторство, Личностный рост',
   psychology: 'Психология, Метафизика, Астрология',
   research: 'Исследования, Информирование, Опросы',
   qualityControl: 'Контроль качества, NPS, Отзывы',
   callCenter: 'Контакт-центр, Обзвон, Актуализация',
   b2b: 'Иное направление, B2B',
   b2c: 'Иное направление, B2C',
};
// export const businessAreaLabels: Record<string, string> = {
//    advertising: 'Реклама',
// };

export const scenarioTypeLabels: Record<string, string> = {
   distance_selling: 'Дистанционные продажи',
   invite_to_selling_spot: 'Приглашение в точку продаж',
   consulting: 'Информирование и консультирование',
   hiring: 'Предложение вакансий и трудоустройства',
   technical_support: 'Тех. обслуживание',
   clients_support: 'Работа с негативом и сопровождение клиентов',
   social_poll: 'Социальные опросы',
   kyc: 'Верификация и валидация контактов',
};

export const contactBaseTypeLabels: Record<string, string> = {
   cold_base: 'Холодные новые контакты',
   hot_base: 'Теплые новые контакты',
   active_base: 'Действующая база контактов',
   active_clients: 'Действующие клиенты (уже купили)',
   old_base: 'Старая база контактов и отказники',
   random_base: 'Вперемешку (не имеет значения)',
};
