import { ReactComponent as IconFilterArrow } from '@assets/icons/filterArrow.svg';
import { ReactComponent as IconSortArrows } from '@assets/icons/sortArrowsIcon.svg';

export const clientsFilterOptions = [
   {
      name: 'script',
      text: 'По виду скрипта',
      options: [
         { label: 'От А до Я', Icon: IconFilterArrow },
         { label: 'От Я до A', Icon: IconFilterArrow },
      ],
   },
   {
      name: 'outcome',
      text: 'По виду исхода',
      options: [
         { label: 'Сначала успешные', Icon: IconFilterArrow },
         { label: 'Сначала не успешные', Icon: IconFilterArrow },
      ],
   },
   {
      Icon: IconSortArrows,
      name: 'dateOutcome',
      text: 'По дате исхода',
      options: [
         { label: 'Сначала новые', Icon: IconFilterArrow },
         { label: 'Сначала старые', Icon: IconFilterArrow },
      ],
   },
   {
      Icon: IconSortArrows,
      name: 'dateAdded',
      text: 'По виду исхода',
      options: [
         { label: 'Сначала новые', Icon: IconFilterArrow },
         { label: 'Сначала старые', Icon: IconFilterArrow },
      ],
   },
   {
      name: 'manager',
      text: 'По менеджеру',
      options: [
         { label: 'От А до Я', Icon: IconFilterArrow },
         { label: 'От Я до A', Icon: IconFilterArrow },
      ],
   },
];
