// blockArr.ts

import { BlockSelect } from '../interfaces/blockSelect';

export const blockTypes: Array<BlockSelect> = [
   {
      text: 'Узел',
      type: 'node',
      color: '#FF910F',
   },
   {
      text: 'Реакция',
      type: 'reaction',
      color: '#00BFFF',
   },
];
