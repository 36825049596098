import React, { useState, useRef, FC } from 'react';

import { useTheme } from '@hooks/useTheme';

import { Box, Typography } from '@mui/material';
import { ButtonAction } from '@shared/buttons';
import { SortPopUp } from '@services/sorts';
import { ReactComponent as IconFilter } from '@assets/icons/filter.svg';

import type { ClientsFiltersProps } from './type';

import { inner, filterBoxStyles, SortButtonstyles } from './style';

import { colorsUI } from 'constants/colorsUI';
import { InputSearch } from 'shared/inputs';
import { logsFilterOptions } from '@constants/filters';

const { light, light50, light400, light800, black200 } = colorsUI;

export const LogsFilters: FC<ClientsFiltersProps> = ({ onClickOpenModal }) => {
   const [filters, setFilters] = useState<Record<string, number>>({
      script: 0,
      outcome: 0,
      manager: 0,
      dateOutcome: 0,
      dateAdded: 0,
   });

   const { theme } = useTheme();

   const inputRef = useRef<HTMLInputElement>(null);

   return (
      <Box sx={inner(theme)}>
         <ButtonAction
            sx={SortButtonstyles(theme) as object}
            backgroundColor={light50}
            backgroundColorHover={light400}
            color={light800}
            colorHover={light}
            Icon={IconFilter}
            label="Фильтр"
            onClick={onClickOpenModal}
         />

         <Box sx={filterBoxStyles(theme)}>
            <Typography sx={{ color: black200 }}>Сортировка по:</Typography>
            <SortPopUp value={filters} setValue={setFilters} sorts={logsFilterOptions} />
         </Box>
         <InputSearch inputRef={inputRef} placeholder="Поиск" />
      </Box>
   );
};
