import { SxProps, Theme } from '@mui/material';
import { colorsUI } from '@constants/colorsUI';

const { black200, black500 } = colorsUI;

export const filterButtonMoreStyle = ({ theme }: { theme: Theme }): SxProps<Theme> => ({
   justifyContent: 'start',
   [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
   },
});

export const filterButtonMoreTextStyle = (): SxProps<Theme> => ({
   color: black200,
   padding: 0,
   width: 'auto',
   transition: 'all .5s',
   '&:hover': {
      color: black500,
      fontWeight: 700,
   },
});
