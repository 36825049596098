import AuthLayout from './layout/AuthLayout';

import DashboardRouter from './components/dashboardRouter';
import { Provider as DashboardProvider } from 'react-redux';
import store from './store';

const Dashboard = () => {
   return (
      <DashboardProvider store={store}>
         <AuthLayout>
            <DashboardRouter />
         </AuthLayout>
      </DashboardProvider>
   );
};

export default Dashboard;
