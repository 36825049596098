import React, { useState, MouseEvent, useMemo, FC } from 'react';

import { useProjectData } from '@hooks/hooksApi/project/useProjectData';
import { useTheme } from '@hooks/useTheme';

import { Box, Theme, useMediaQuery } from '@mui/material';
import { LoaderDefault } from '@shared/loader';
import { TableDefault, TableMobile } from '@shared/tables';
import { TableActionsButtonMoreOptions } from '@components/tableActions';
import { ButtonAction } from '@shared/buttons';

import { IProjectTable } from '@projectTypes/projects';
import { ColumnTable } from '@shared/tables/types';
import { LinkDefault } from '@shared/links';
import { PopupDefault } from '@shared/popups';
import { ListPopupActionContents } from '@shared/lists';
import { FilterButton } from '@shared/filters';

import { ReactComponent as IconPerson } from '@assets/icons/personIcon.svg';
import { ReactComponent as IconMail } from '@assets/icons/mail.svg';
import { ReactComponent as IconAnalytics } from '@assets/icons/analyticsPopupIcon.svg';
import { ReactComponent as IconLogs } from '@assets/icons/logsIcon.svg';
import { ReactComponent as IconDelete } from '@assets/icons/deleteKrest.svg';
import { ReactComponent as IconEdit } from '@assets/icons/edit.svg';
import { ReactComponent as IconPlus } from '@assets/icons/plus.svg';

import { handleClickPopup } from '@utils/handleClickPopup';

import {
   accountClientsButtonActionStyles,
   accountClientsStyles,
   accountClientsTableItemDraftTextStyles,
   accountClientsTableLinkStyles,
} from './style';

const arrValuesButton = ['все проекты', 'Личные проекты', 'Я менеджер'];

export const AccountClients = () => {
   return <Box>тут точно также как и в проектаъ тока я не знаю откуда данные тянуть</Box>;
};
