import { Typography } from '@mui/material';

import { extractDate, extractTime, calculateWorkTimeInMinutes } from '@utils/date';
import { mapAddTextInDate } from '@utils/mappers';

import type { HistoryScriptDataParams } from './type';

import {
   historyScriptHeadStyles,
   historyScriptDescStyles,
   historyScriptFooterStyles,
   historyScriptItemStyles,
   historyScriptTextStyles,
   historyScriptTextLightStyles,
} from './style';

const startTime = '2024-12-17 09:00:00';
const endTime = '2024-12-17 18:30:45';

export const historyScriptData = ({
   data,
   color,
}: {
   data: HistoryScriptDataParams;
   color: string;
}) => [
   {
      content: <Typography variant="h2">Скрипт завершен</Typography>,
      rowStart: 1,
      rowEnd: 2,
      colStart: 1,
      colEnd: 4,
      styles: historyScriptHeadStyles,
   },
   {
      content: (
         <>
            <Typography sx={historyScriptDescStyles} variant="body1">
               Дата запуска
            </Typography>
            <Typography sx={historyScriptTextStyles} variant="body1">
               {data.dataStart ? extractDate(data.dataStart) : '-'}
            </Typography>
         </>
      ),
      rowStart: 2,
      rowEnd: 3,
      colStart: 1,
      colEnd: 2,
      styles: historyScriptItemStyles,
   },
   {
      content: (
         <>
            <Typography sx={historyScriptDescStyles} variant="body1">
               Дата завершения:
            </Typography>
            <Typography sx={historyScriptTextStyles} variant="body1">
               {data.dataFinish ? extractDate(data.dataFinish) : '-'}
            </Typography>
         </>
      ),
      rowStart: 2,
      rowEnd: 3,
      colStart: 2,
      colEnd: 3,
      styles: historyScriptItemStyles,
   },
   {
      content: (
         <>
            <Typography sx={historyScriptDescStyles} variant="body1">
               Длительность работы:
            </Typography>
            <Typography sx={historyScriptTextLightStyles} variant="body1">
               {data.dataStart && data.dataFinish
                  ? mapAddTextInDate(calculateWorkTimeInMinutes(startTime, endTime), ':')
                  : '-'}
            </Typography>
         </>
      ),
      rowStart: 2,
      rowEnd: 4,
      colStart: 3,
      colEnd: 4,
      styles: historyScriptItemStyles,
   },
   {
      content: (
         <>
            <Typography sx={historyScriptDescStyles} variant="body1">
               Время запуска:
            </Typography>
            <Typography sx={historyScriptTextStyles} variant="body1">
               {data.dataStart ? extractTime(data.dataStart) : '-'}
            </Typography>
         </>
      ),
      rowStart: 3,
      rowEnd: 4,
      colStart: 1,
      colEnd: 2,
      styles: historyScriptItemStyles,
   },
   {
      content: (
         <>
            <Typography sx={historyScriptDescStyles} variant="body1">
               Время завершения:
            </Typography>
            <Typography sx={historyScriptTextStyles} variant="body1">
               {data.dataFinish ? extractTime(data.dataFinish) : '-'}
            </Typography>
         </>
      ),
      rowStart: 3,
      rowEnd: 4,
      colStart: 2,
      colEnd: 3,
      styles: historyScriptItemStyles,
   },
   {
      content: (
         <>
            <Typography sx={historyScriptDescStyles} variant="body1">
               Исход
            </Typography>
            <Typography sx={{ ...historyScriptTextStyles, color }} variant="body1">
               {data.status ? data.status : '-'}
            </Typography>
         </>
      ),
      rowStart: 4,
      rowEnd: 5,
      colStart: 1,
      colEnd: 4,
      styles: historyScriptFooterStyles,
   },
];
