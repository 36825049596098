import React, { FC } from 'react';
import { Button } from '@mui/material';
import type { ButtonActiveProps } from '../types';

import { buttonActiveStyles } from '../styles';

export const ButtonActive: FC<ButtonActiveProps> = ({
   isActive,
   color,
   activeColor,
   backgroundColor,
   activeBackgroundColor,
   onClick,
   children,
   disabled = false,
   label,
   styles,
   sx,
}) => {
   return (
      <Button
         sx={{
            ...styles,
            ...(sx ?? {}),
            ...(buttonActiveStyles({
               isActive,
               color,
               backgroundColor,
               activeColor,
               activeBackgroundColor,
               disabled,
            }) as object),
         }}
         onClick={onClick}
      >
         {children ?? label}
      </Button>
   );
};
