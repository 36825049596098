import { useLeadCreateMutation } from 'app/dashboard/store/api/leadApi';

import type { ILeadCreateParams } from '@projectTypes/lead';
import type { IErrorResponse } from '@projectTypes/apiTypes';

export const useLeadCreate = () => {
   const [createLead, { data, isError, error, isLoading, isSuccess }] =
      useLeadCreateMutation();

   const createLeadData = async (newLead: ILeadCreateParams) => {
      try {
         const result = await createLead(newLead).unwrap();
         console.log('Ответ от сервера:', result);
         console.log(data);
         return result;
      } catch (error) {
         console.error('Ошибка:', error);
         throw new Error(error as string);
      }
   };

   return {
      createLeadData,
      isError,
      data,
      error: error as IErrorResponse,
      isLoading,
      isSuccess,
   };
};
