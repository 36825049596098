import z from 'zod';

import type { ClientsAddFormErrorsType } from '../../types';

// сдеалть файлик с регулярками
const phoneRegex = /^\d{10,15}$/;

export const clientAddSchema = z.object({
   name: z.string().min(1, 'ФИО Клиента обязательно'),
   phone: z.string().regex(phoneRegex, 'Некорректный номер телефона'),
   source: z.string().min(1, 'Источник обязателен'),
});

export const validateClientAddForm = (data: {
   name: string;
   phone: string;
   source: string;
}) => {
   const result = clientAddSchema.safeParse(data);

   if (!result.success) {
      const errors: ClientsAddFormErrorsType = {};
      result.error.errors.forEach((error) => {
         const field = error.path[0] as keyof ClientsAddFormErrorsType;
         errors[field] = error.message;
      });
      return { success: false, errors };
   }

   return { success: true, errors: {} };
};
