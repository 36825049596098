import { CSSProperties } from 'react';
import { SxProps, Theme } from '@mui/material';

import { colorsUI } from '@constants/colorsUI';

const { transperent, light600, black100, black200, black300 } = colorsUI;

export const historyScriptDateStartedStyles: CSSProperties = {
   color: black200,
   textAlign: 'center',
};

export const historyScriptInnerStyles = ({
   theme,
}: {
   theme: Theme;
}): SxProps<Theme> => ({
   display: 'flex',
   flexDirection: 'column',
   alignItems: 'center',
   gap: '24px',
});

export const historyScriptInnerHeadStyles = ({
   theme,
}: {
   theme: Theme;
}): SxProps<Theme> => ({
   width: '100%',
   textAlign: 'left',
   color: black300,
   borderBottom: `1px solid ${black100}`,
   padding: '0 32px',
   [theme.breakpoints.down('md')]: {
      padding: '32px',
   },
});

export const historyScriptInnerButtonIsVisibleStyles = ({
   theme,
}: {
   theme: Theme;
}): SxProps<Theme> => ({
   width: 'fit-content',
   background: transperent,
   color: light600,
   [theme.breakpoints.down('sm')]: {
      width: '100%',
   },
});

export const historyScriptInnerButtonIsVisibleIconStyles = ({
   isVisible,
}: {
   isVisible: boolean;
}): SxProps<Theme> => ({
   transform: isVisible ? 'rotate(45deg)' : 'rotate(0deg)',
   transition: 'transform .5s ease-in-out',
});
