import React, { FC } from 'react';

import { Button } from '@mui/material';

import { ReactComponent as IconMore } from '@assets/icons/more-options-icon.svg';

import type { ButtonMoreProps } from '../types';

import { buttonMoreStyles } from '../styles';

export const ButtonMore: FC<ButtonMoreProps> = ({ onClick, styles, sx, Icon }) => {
   return (
      <Button
         onClick={onClick}
         sx={{ ...buttonMoreStyles, ...((sx as object) ?? {}) }}
         style={styles}
      >
         {Icon ? <Icon /> : <IconMore />}
      </Button>
   );
};
