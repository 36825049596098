import { SxProps, Theme } from '@mui/material';
import type { ButtonActiveStylesParams } from '../types';

import { colorsUI } from '@constants/colorsUI';

const { light200, light500, black600, transperent } = colorsUI;

export const buttonActiveStyles = ({
   isActive,
   disabled,
   activeBackgroundColor = light200,
   activeColor = black600,
   backgroundColor = transperent,
   color = light500,
}: ButtonActiveStylesParams): SxProps<Theme> => ({
   background: isActive ? activeBackgroundColor : backgroundColor,
   color: isActive ? activeColor : color,
   border: `1px solid ${light500}`,
   fontSize: '0.875rem',
   fontWeight: 700,
   lineHeight: '16px',
   padding: '8px 12px',
   whiteSpace: 'nowrap',
   minWidth: 'auto',
   ...(!disabled && {
      '&:hover': {
         background: activeBackgroundColor,
         color: activeColor,
      },
   }),
});
