import React from 'react';
import { ColumnCentered } from '@shared/column';
import { Box, Typography } from '@mui/material';
import { ButtonAction, ButtonDefault } from '@shared/buttons';

import imageNoneClientsPath from '@assets/images/clients.png';
import { ReactComponent as IconImport } from '@assets/icons/importFile.svg';

import { colorsUI } from '@constants/colorsUI';

import {
   clientsNoneDataImageStyles,
   clientsNoneDataRTextStyles,
   clientsNoneDataBoxStyles,
   clientsNoneDataButtonStyles,
} from './style';

const { black400, black500, transperent } = colorsUI;

export const ClientsNoneData = () => {
   const onClickdouwload = () => {
      alert('download');
   };
   const onClickImport = () => {
      alert('import');
   };

   return (
      <ColumnCentered>
         <Box
            component="img"
            sx={clientsNoneDataImageStyles}
            src={imageNoneClientsPath}
            alt="нет клиентов"
         />
         <Typography sx={clientsNoneDataRTextStyles} variant="body1">
            У вас пока нет клиентов, которым можно было бы позвонить по скрипту, или
            которые ранее были обработаны с помощью внешних интеграций
         </Typography>
         <Box sx={clientsNoneDataBoxStyles}>
            <ButtonAction
               Icon={IconImport}
               label="Импортировать CSV"
               onClick={onClickImport}
               iconStyles={{ fill: 'none' }}
            />
            <ButtonDefault
               label="Скачать CSV шаблон"
               onClick={onClickdouwload}
               backgroundColor={transperent}
               backgroundColorHover={transperent}
               color={black400}
               colorHover={black500}
               sx={clientsNoneDataButtonStyles}
            />
         </Box>
      </ColumnCentered>
   );
};
