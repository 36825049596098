import React, { CSSProperties, FC } from 'react';

import { Box, Typography } from '@mui/material';
import { ButtonMore } from 'shared/buttons';
import { Link as LinRRD } from 'react-router-dom';
import { Link as LinkMUI } from '@mui/material';

import {
   TableActionsButtonMoreOptionsStyles,
   TableActionsButtonMoreOptionsInnerStyles,
} from './style';

import type { TableActionsButtonMoreOptionsProps } from './type.js';

export const TableActionsButtonMoreOptions: FC<TableActionsButtonMoreOptionsProps> = ({
   onClick,
   text,
   styles = {},
   href,
   stylesButtonMore,
   sx,
   sxButtonMore,
   sxInner,
   Icon,
}) => {
   return (
      <Box sx={{ ...TableActionsButtonMoreOptionsInnerStyles, ...(sxInner ?? {}) }}>
         {href ? (
            <LinkMUI
               sx={{
                  ...TableActionsButtonMoreOptionsStyles,
                  ...styles,
                  ...((sx ?? {}) as object),
               }}
               component={LinRRD}
               to={href}
            >
               {text}
            </LinkMUI>
         ) : (
            <Typography
               sx={{
                  ...TableActionsButtonMoreOptionsStyles,
                  ...styles,
                  ...((sx ?? {}) as object),
               }}
               variant="body2"
            >
               {text}
            </Typography>
         )}
         <ButtonMore
            Icon={Icon}
            onClick={onClick}
            styles={{
               ...(stylesButtonMore as CSSProperties),
               ...(sxButtonMore ?? ({} as object)),
            }}
         />
      </Box>
   );
};
