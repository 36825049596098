import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Tree from '../Tree/Tree';
import { ConstructorLayout } from './constructor.css';
import { ButtonAction } from '@shared/buttons';

const Constructor = () => {
   const [isSaveEnabled, setIsSaveEnabled] = useState(false);
   const [handleSave, setHandleSave] = useState<() => void>(() => () => {});
   const { slug } = useParams<{ slug: string }>();

   if (!slug) {
      return <div>Ошибка: не указан идентификатор проекта</div>;
   }

   return (
      <ConstructorLayout>
         <div style={{ width: '100%' }}>
            <ButtonAction
               label="Сохранить"
               onClick={handleSave}
               disabled={!isSaveEnabled}
               sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginLeft: 'auto',
                  marginBottom: '24px',
               }}
            />
            <Tree
               slug={slug}
               setIsSaveEnabled={setIsSaveEnabled}
               handleSave={(saveFunc) => setHandleSave(() => saveFunc)}
            />
         </div>
      </ConstructorLayout>
   );
};

export default Constructor;
