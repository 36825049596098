import React, { FC } from 'react';

import { useTheme } from '@hooks/useTheme';

import { Box, Typography } from '@mui/material';
import type { HistoryScriptItemProps } from './type';

import {
   historyScriptItemStyles,
   historyScriptItemReactionTypeStyles,
   historyScriptItemDescStyles,
} from './styles';

import { HistoryReactionType } from '@enums/history';

export const HistoryScriptItem: FC<HistoryScriptItemProps> = ({ historyMessage }) => {
   const { reaction_type, stage } = historyMessage;

   const { theme } = useTheme();

   const isObjection =
      historyMessage.reaction_type === HistoryReactionType.Negative ||
      historyMessage.reaction_type === HistoryReactionType.Objection;

   const isClient = Boolean(reaction_type);

   return (
      <Box
         sx={{
            ...historyScriptItemStyles({
               theme,
               isObjection,
               isClient,
            }),
         }}
      >
         <Box>
            <Typography
               sx={{ ...historyScriptItemDescStyles({ theme }) }}
               variant="body2"
            >
               {isClient ? 'Абонент' : 'Менеджер:'}
            </Typography>
            <Typography
               variant="subtitle2"
               sx={{
                  ...historyScriptItemReactionTypeStyles({
                     theme,
                     isNegative: isObjection,
                  }),
               }}
            >
               {reaction_type}
            </Typography>
            <Typography variant="body1">{historyMessage.text}</Typography>
         </Box>

         {isObjection && (
            <Box component="span">
               <Typography>Возжражение отработано</Typography>
               <Typography>Триггер - Смена статуса на {stage}</Typography>
            </Box>
         )}
      </Box>
   );
};
