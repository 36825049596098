import { CSSProperties } from 'react';

import { SxProps, Theme } from '@mui/material/styles';

export const layoutProfileInnerStyles: CSSProperties = {
   display: 'flex',
};

export const layoutProfileMainrStyles: SxProps<Theme> = (theme: Theme) => ({
   display: 'flex',
   width: '100%',
   flexDirection: 'column',
   padding: '32px 48px 24px',
   flexGrow: 1,
   overflow: 'auto',
   [theme.breakpoints.down('md')]: {
      padding: '32px 15px 24px',
      overflow: 'visible',
   },
});
