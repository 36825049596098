import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useProjectCreate } from '@hooks/hooksApi/project/useProjectCreate';

import { ColumnDashboard } from 'shared/column';
import { ProfileHeader } from '@componentsDashBoard/profileHeader';
import { FormProject } from '../forms/formProject';
import { Box } from '@mui/material';

import type { IProjectCreate } from '@projectTypes/projects/projectTypes';

import { headerBoxFormStyles, headerBoxModileStyles } from './style';

export const CreateProjects = () => {
   const { createProjectData, isLoading, isError, error } = useProjectCreate();

   const navigate = useNavigate();

   const onSave = async (formData: IProjectCreate) => {
      const result = await createProjectData(formData);
      console.log(result.data.slug);
      if (result.data.slug) {
         const slug = result.data.slug;

         navigate(`/projects/constructor/${slug}`);
      } else {
         alert(result);
      }
   };

   return (
      <ColumnDashboard>
         <ProfileHeader
            styles={headerBoxModileStyles as object}
            title="Создание проекта"
         ></ProfileHeader>
         <Box sx={headerBoxFormStyles}>
            <FormProject
               error={error}
               isError={isError}
               isLoading={isLoading}
               onSubmit={onSave}
            />
         </Box>
      </ColumnDashboard>
   );
};
