import React, { FC, useEffect, useState, useRef } from 'react';
import {
   TextField,
   Select,
   MenuItem,
   Button,
   FormControl,
   InputLabel,
   Checkbox,
   FormControlLabel,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningIcon from '@mui/icons-material/Warning';
import { IBlock } from '../../../../interfaces/constructor/block';
import { ButtonAction } from '@shared/buttons';

const SideMenu: FC<{
   blockType: 'node' | 'reaction';
   currentBlock: IBlock | null;
   onSave: (properties: Partial<IBlock>) => void;
   onClose: () => void;
   onDelete: (id: number) => void;
   stages: string[];
   onAddStage: (newStage: string) => void;
}> = ({ blockType, currentBlock, onSave, onClose, onDelete, stages, onAddStage }) => {
   const [properties, setProperties] = useState<Partial<IBlock>>(
      currentBlock?.properties || {},
   );
   const [visible, setVisible] = useState(false);
   const [newStage, setNewStage] = useState('');

   const menuRef = useRef<HTMLDivElement>(null);

   useEffect(() => {
      if (currentBlock) {
         setProperties(currentBlock.properties || {});
         setVisible(true);
      }
   }, [currentBlock]);

   useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
         if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            handleCancel();
         }
      };

      if (visible) {
         document.addEventListener('mousedown', handleClickOutside);
      }

      return () => {
         document.removeEventListener('mousedown', handleClickOutside);
      };
   }, [visible]);

   const handleChange = (key: keyof IBlock, value: any) => {
      setProperties((prev) => ({
         ...prev,
         [key]: value,
      }));
   };

   const handleAddStage = () => {
      if (newStage.trim() && !stages.includes(newStage)) {
         onAddStage(newStage);
         setNewStage('');
      }
   };

   const handleSave = () => {
      onSave(properties);
      setVisible(false);
      setTimeout(onClose, 300);
   };

   const handleDelete = () => {
      if (currentBlock) {
         onDelete(currentBlock.id);
         setVisible(false);
         setTimeout(onClose, 300);
      }
   };

   const handleCancel = () => {
      setVisible(false);
      setTimeout(onClose, 300);
   };

   return (
      <div
         ref={menuRef}
         onMouseDown={(e) => e.stopPropagation()}
         style={{
            width: '300px',
            padding: '16px',
            borderLeft: '1px solid #ddd',
            position: 'absolute',
            right: visible ? 0 : '-300px',
            top: '110px',
            height: '100vh',
            background: '#fff',
            transition: 'right 0.3s ease',
         }}
      >
         <h3>
            {blockType === 'node' ? 'Редактирование Узла' : 'Редактирование Реакции'}
         </h3>

         {blockType === 'node' && (
            <>
               <TextField
                  fullWidth
                  label="Текст оператора"
                  margin="normal"
                  multiline
                  variant="outlined"
                  value={properties.text || ''}
                  onChange={(e) => handleChange('text', e.target.value)}
               />
               <TextField
                  fullWidth
                  label="Подсказка"
                  multiline
                  margin="normal"
                  variant="outlined"
                  value={properties.hint || ''}
                  onChange={(e) => handleChange('hint', e.target.value)}
               />
               <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel>Стадия</InputLabel>
                  <Select
                     value={properties.stage || ''}
                     onChange={(e) => handleChange('stage', e.target.value)}
                     displayEmpty
                     label="Стадия"
                  >
                     {stages.map((stage) => (
                        <MenuItem key={stage} value={stage}>
                           {stage}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>
               <div style={{ display: 'flex', gap: '8px', marginTop: '16px' }}>
                  <TextField
                     fullWidth
                     label="Новая стадия"
                     variant="outlined"
                     value={newStage}
                     onChange={(e) => setNewStage(e.target.value)}
                  />
                  <Button
                     variant="contained"
                     onClick={handleAddStage}
                     style={{ minWidth: 'auto' }}
                  >
                     +
                  </Button>
               </div>
               <FormControlLabel
                  control={
                     <Checkbox
                        checked={properties.in_objection === '1'}
                        onChange={(e) =>
                           handleChange('in_objection', e.target.checked ? '1' : '0')
                        }
                     />
                  }
                  label="Отработка возражения"
               />
            </>
         )}

         {blockType === 'reaction' && (
            <>
               <TextField
                  fullWidth
                  label="Текст реакции"
                  multiline
                  margin="normal"
                  variant="outlined"
                  value={properties.text || ''}
                  onChange={(e) => handleChange('text', e.target.value)}
               />
               <Select
                  fullWidth
                  value={properties.reaction_type || 'positive'}
                  onChange={(e) => handleChange('reaction_type', e.target.value)}
                  displayEmpty
                  sx={{
                     '& .MuiSelect-select': {
                        display: 'flex',
                        alignItems: 'center',
                     },
                  }}
               >
                  <MenuItem
                     value="positive"
                     sx={{
                        transition: 'background-color 0.3s ease',
                        ':hover': {
                           backgroundColor: 'rgba(0, 255, 0, 0.1)',
                        },
                     }}
                  >
                     <CheckCircleIcon style={{ marginRight: 8, color: 'green' }} />
                     Положительная
                  </MenuItem>
                  <MenuItem
                     value="negative"
                     sx={{
                        transition: 'background-color 0.3s ease',
                        alignItems: 'center',
                        ':hover': {
                           backgroundColor: 'rgba(255, 0, 0, 0.1)',
                        },
                     }}
                  >
                     <CancelIcon style={{ marginRight: 8, color: 'red' }} />
                     Отрицательная
                  </MenuItem>
                  <MenuItem
                     value="objection"
                     sx={{
                        transition: 'background-color 0.3s ease',
                        ':hover': {
                           backgroundColor: 'rgba(255, 165, 0, 0.1)',
                        },
                     }}
                  >
                     <WarningIcon style={{ marginRight: 8, color: 'orange' }} />
                     Возражение
                  </MenuItem>
               </Select>

               <TextField
                  fullWidth
                  label="Триггер действия"
                  margin="normal"
                  variant="outlined"
                  value={properties.trigger || ''}
                  onChange={(e) => handleChange('trigger', e.target.value)}
               />
               <Select
                  fullWidth
                  value={properties.exodus || ''}
                  onChange={(e) => handleChange('exodus', e.target.value)}
                  displayEmpty
               >
                  <MenuItem value="">Не указан</MenuItem>
                  <MenuItem value="success">Успех</MenuItem>
                  <MenuItem value="failure">Неудача</MenuItem>
               </Select>
            </>
         )}

         <div style={{ marginTop: '16px' }}>
            <ButtonAction onClick={handleSave} styles={{ marginBottom: '8px' }}>
               Сохранить
            </ButtonAction>
            <div style={{ display: 'flex', gap: '16px' }}>
               <button
                  onClick={handleDelete}
                  style={{
                     cursor: 'pointer',
                     fontSize: '16px',
                     color: 'red',
                     border: 'none',
                     outline: 'none',
                     background: 'transparent',
                  }}
               >
                  Удалить
               </button>
               <button
                  style={{
                     cursor: 'pointer',
                     fontSize: '16px',
                     border: 'none',
                     outline: 'none',
                     background: 'transparent',
                  }}
                  onClick={handleCancel}
               >
                  Отмена
               </button>
            </div>
         </div>
      </div>
   );
};

export default SideMenu;
