import React, { FC, FormEvent, useEffect, useState } from 'react';

import { useTheme } from '@hooks/useTheme';
import { useProfileUpdateFIO } from '@hooks/hooksApi';
import { useToggleModal as useToggleAlert } from '@hooks/hooksUI';

import { AlertSnackbar } from '@shared/alert';
import { FormAccount } from '@components/forms';
import { Box } from '@mui/material';
import { InputDefault } from '@shared/inputs';
import { ButtonDefault } from '@shared/buttons';
import { ErrorMessage } from '@shared/errorMessage';
import { LoaderDefault } from '@shared/loader';

import handleInput from 'utils/handleInput';
import { updateFIO } from 'app/dashboard/store/slices/profile/profileSlice';

import type { IUpdateFIORequest, IProfileFIO } from '@projectTypes/profile';
import type { FormEditFIOProps } from './type';

import {
   accountPersonalDataFormItemStyles,
   accountPersonalDataFormTextfieldStyles,
} from './style';

import { colorsUI } from '@constants/colorsUI';
import useAppDispatch from 'app/dashboard/store/hooks/useAppDispatch';

const { light } = colorsUI;

export const FormEditFIO: FC<FormEditFIOProps> = ({ firstName, lastName, surname }) => {
   console.log(firstName, lastName, surname);
   const [firstNameState, setFirstNameState] = useState(firstName ?? '');
   const [lastNameState, setLastNameState] = useState(lastName ?? '');
   const [surnameState, setSurnameState] = useState(surname ?? '');

   const dispatch = useAppDispatch();

   const { theme } = useTheme();

   const { updateFIOData, error, isError, isLoading, isSuccess } = useProfileUpdateFIO();

   const { isOpen, setIsOpen } = useToggleAlert();

   useEffect(() => {
      if (isSuccess) {
         setIsOpen(true);
         const timer = setTimeout(() => {
            setIsOpen(false);
         }, 3000);

         return () => clearTimeout(timer);
      }
   }, [isSuccess, setIsOpen]);

   useEffect(() => {
      if (firstName) setFirstNameState(firstName);
      if (lastName) setLastNameState(lastName);
      if (surname) setSurnameState(surname);
   }, [firstName, lastName, surname]);

   const onSubmit = async (e: FormEvent) => {
      e.preventDefault();

      const data: IUpdateFIORequest = {
         first_name: firstNameState,
         last_name: lastNameState,
         surname: surnameState,
      };
      const dataCamelCase: IProfileFIO = {
         firstName: firstNameState,
         lastName: lastNameState,
         surname: surnameState,
      };

      try {
         await updateFIOData(data);
         dispatch(updateFIO(dataCamelCase));
      } catch (err) {
         console.error(err);
      }
   };

   return (
      <FormAccount onSubmit={onSubmit}>
         <AlertSnackbar
            setOpen={setIsOpen}
            open={isOpen}
            message={isError ? error.data.data.message : 'Успех'}
         />

         <Box sx={accountPersonalDataFormItemStyles({ theme })}>
            <InputDefault
               stylesWrapper={accountPersonalDataFormTextfieldStyles}
               stylesForm={accountPersonalDataFormTextfieldStyles}
               placeholder="Фамилия"
               value={lastNameState}
               onChange={(e) => handleInput(e, setLastNameState)}
            />
         </Box>
         <Box sx={accountPersonalDataFormItemStyles({ theme })}>
            <InputDefault
               stylesWrapper={accountPersonalDataFormTextfieldStyles}
               stylesForm={accountPersonalDataFormTextfieldStyles}
               placeholder="Имя"
               value={firstNameState}
               onChange={(e) => handleInput(e, setFirstNameState)}
            />
         </Box>
         <Box sx={accountPersonalDataFormItemStyles({ theme })}>
            <InputDefault
               stylesWrapper={accountPersonalDataFormTextfieldStyles}
               stylesForm={accountPersonalDataFormTextfieldStyles}
               placeholder="Отчество"
               value={surnameState}
               onChange={(e) => handleInput(e, setSurnameState)}
            />
         </Box>
         {isError && <ErrorMessage message={error.data.data.message} />}
         <ButtonDefault type="submit">
            {isLoading ? <LoaderDefault color={light} /> : 'Отправить'}
         </ButtonDefault>
      </FormAccount>
   );
};
