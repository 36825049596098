import { CSSProperties } from 'react';

import { colorsUI } from '@constants/colorsUI';

const { black400 } = colorsUI;

export const clientsDataTableStyles: CSSProperties = {
   display: 'flex',
   flexDirection: 'column',
   gap: '8px',
};

export const clientsDataTableCellButtonMoreStyles: CSSProperties = {
   color: black400,
};
