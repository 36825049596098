import React, { FC } from 'react';

import type { ListAccordionProps } from '../types';
import { Typography, Box } from '@mui/material';

export const ListAccordion: FC<ListAccordionProps> = ({
   items,
   open,
   setOpen,
   styles,
}) => {
   const onClick = () => {
      setOpen(!open);
   };

   return (
      <>
         {items.map((el, i) => (
            <Box key={i} onClick={onClick}>
               <Typography sx={{ ...styles }} key={i}>
                  {el}
               </Typography>
            </Box>
         ))}
      </>
   );
};
