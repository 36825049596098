import React, { FC } from 'react';

import { Box } from '@mui/material';
import { ButtonActive } from '@shared/buttons';

import type { SortButtonsBodyProps } from './type';

import { sortButtonsStyles } from './style';

export const SortButtons: FC<SortButtonsBodyProps> = ({
   activeIndex,
   setActiveIndex,
   arrValues,
   styleButton,
   styleInner,
   sxButton,
   sxInner,
}) => {
   const handleClickSortButtons = (index: number) => {
      setActiveIndex(index);
   };

   return (
      <Box sx={{ ...sortButtonsStyles, ...styleInner, ...((sxInner ?? {}) as object) }}>
         {arrValues.map((el, i) => (
            <ButtonActive
               styles={{ ...styleButton }}
               sx={{ ...sxButton }}
               key={el}
               onClick={() => handleClickSortButtons(i)}
               isActive={i === activeIndex}
            >
               {el}
            </ButtonActive>
         ))}
      </Box>
   );
};
