export const apiAuth = '/auth';

export const apiAuthSignUp = '/signup';
export const apiAuthLogin = '/login';
export const apiAuthResetPassword = '/reset-password';

export const apiDashboard = '/dashboard';

export const apiDashboardProfile = '/profile';

export const apiDashboardProfileChangeEmail = '/change-email';
export const apiDashboardProfileConfirmCode = '/confirm-code';
export const apiDashboardProfileChangePassword = '/change-password';
export const apiDashboardProfileUpdatePhone = '/update-phone';

export const apiDashboardManager = '/manager';
export const apiDashboardManagerInvite = '/invite';
export const apiDashboardManagerPendingInvitations = '/pending-invitations';
export const apiDashboardManagerAttachProjects = '/attach-projects';

export const apiDashboardProject = '/project';
export const apiDashboardProjectCustomFields = '/project-custom-fields';

export const apiDashboardLead = '/lead';
export const apiDashboardLeadRuntime = '/lead-runtime';
export const apiDashboardTree = '/tree';

export const apiDashboardRuntime = '/runtime';

export const apiRuntimeLog = '/runtime-log';

export const apiRuntimeStart = '/start';
export const apiRuntimeClickCell = '/click';
export const apiRuntimeSaveLead = '/save-lead-data';
