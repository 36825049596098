import { useRuntimeLogByUuidQuery } from 'app/dashboard/store/api/runtimeLogApi';

import type { IErrorResponseSimplified } from '@projectTypes/apiTypes';

export const useRuntimeLogsByUiidData = ({ uuid }: { uuid: string }) => {
   const {
      data: runtimeLogHistoryData,
      currentData: runtimeLogHistoryCurrentData,
      isLoading: runtimeLogHistorytIsLoading,
      isError: runtimeLogHistoryIsError,
      isSuccess: runtimeLogHistoryIsSuccess,
      isFetching: runtimeLogHistoryIsFetching,
      error: runtimeLogHistoryError,
   } = useRuntimeLogByUuidQuery({ uuid });

   return {
      runtimeLogHistoryCurrentData,
      runtimeLogHistoryData,
      runtimeLogHistorytIsLoading,
      runtimeLogHistoryIsError,
      runtimeLogHistoryIsSuccess,
      runtimeLogHistoryIsFetching,
      runtimeLogHistoryError: runtimeLogHistoryError as IErrorResponseSimplified,
   };
};
