import { useUpdateProjectMutation } from 'app/dashboard/store/api/projectApi';

import type { IProjectCreate } from '@projectTypes/projects/projectTypes';
import type { IErrorResponse } from '@projectTypes/apiTypes';

export const useProjectUpdate = () => {
   const [
      updateProject,
      {
         isError: isErrorUpdateProject,
         error: errorUpdateProject,
         isLoading: isLoadingUpdateProject,
         isSuccess: isSuccessUpdateProject,
      },
   ] = useUpdateProjectMutation();

   const updateProjectData = async ({
      slug,
      data,
   }: {
      slug?: string;
      data: IProjectCreate;
   }) => {
      try {
         const result = await updateProject({ data, slug }).unwrap();
         console.log(result);
         return result;
      } catch (err) {
         const customError = err as IErrorResponse;
         console.log(customError);

         if ('data' in customError) {
            console.error('Ошибка API:', customError.data.data.message);
            throw new Error(customError.data.data.message);
         } else {
            console.error('Системная ошибка:', err);
            throw new Error('Неизвестная ошибка');
         }
      }
   };

   return {
      updateProjectData,
      isErrorUpdateProject,
      errorUpdateProject: errorUpdateProject as IErrorResponse,
      isLoadingUpdateProject,
      isSuccessUpdateProject,
   };
};
