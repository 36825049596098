import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useProfileQuery } from 'app/dashboard/store/api/profileApi';
import useAppSelector from 'app/dashboard/store/hooks/useAppSelector';
import useAppDispatch from 'app/dashboard/store/hooks/useAppDispatch';
import { login, logout } from 'app/dashboard/store/slices/auth/authSlice';
import { setProfileData } from 'app/dashboard/store/slices/profile/profileSlice';
import { getTokenFromCookie } from '@utils/setTokenInCookie';

export const useAuth = () => {
   const dispatch = useAppDispatch();
   const { isAuthenticated } = useAppSelector((state) => state.auth);
   const navigate = useNavigate();
   const [isProfileLoaded, setIsProfileLoaded] = useState(false);
   const location = useLocation();

   useEffect(() => {
      const token = getTokenFromCookie();

      const isAuthPage =
         location.pathname === '/signin' ||
         location.pathname === '/signup' ||
         location.pathname === '/reset-password';

      if (!token && !isAuthPage) {
         dispatch(logout());
         navigate('/signin');
         return;
      }

      if (token && !isAuthenticated) {
         dispatch(login());
      }
   }, [dispatch, isAuthenticated, navigate]);

   const { data, error, isError, isLoading, isSuccess } = useProfileQuery(undefined, {
      skip: !isAuthenticated || isProfileLoaded,
      refetchOnMountOrArgChange: true,
   });

   useEffect(() => {
      if (isAuthenticated && !isProfileLoaded) {
         if (isLoading) {
            return;
         }

         if (isAuthenticated && location.pathname === '/signin') {
            navigate('/');
         }

         if (isSuccess) {
            setIsProfileLoaded(true);

            dispatch(setProfileData(data));
         }

         if (error) {
            dispatch(logout());
            navigate('/signin');
            console.error('Ошибка при загрузке профиля:', error);
            setIsProfileLoaded(true);
         }
      }
   }, [
      data,
      error,
      isLoading,
      isSuccess,
      isAuthenticated,
      isProfileLoaded,
      dispatch,
      navigate,
   ]);

   return { isLoading, error, isError, data, isSuccess };
};
