import { useEffect, useState } from 'react';
import useAppDispatch from 'app/dashboard/store/hooks/useAppDispatch';
import { useProjectQuery } from 'app/dashboard/store/api/projectApi';
import { setProjectData } from 'app/dashboard/store/slices/projects/projectsSlice';
import type { IErrorResponse } from '@projectTypes/apiTypes';

export const useProjectData = () => {
   const dispatch = useAppDispatch();
   const {
      data: projectData,
      isSuccess: projectIsSuccess,
      isLoading: projectIsLoading,
      isFetching: projectIsFetching,
      isError: projectIsError,
      refetch: refetch,
      error: errorMessage,
   } = useProjectQuery({});
   const [slug, setSlug] = useState<string | null>(null);

   useEffect(() => {
      console.log(projectData?.data);
      if (projectIsSuccess && projectData) {
         dispatch(setProjectData(projectData));

         const projectSlug = projectData?.data[0]?.slug;
         if (projectSlug) {
            setSlug(projectSlug);
         }
      }
   }, [projectData, projectIsSuccess, dispatch]);

   return {
      slug,
      projectData,
      projectIsLoading,
      projectIsFetching,
      projectIsError,
      refetch,
      errorMessage: errorMessage as IErrorResponse,
   };
};
