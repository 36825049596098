import React, { FC } from 'react';

import { useTheme } from '@emotion/react';

import { Box, List, ListItem, Typography } from '@mui/material';

import type { ClientCardProps } from './type';

import { getStatusColorLeadRuntime, getStatusNameLeadRuntime } from '@utils/getters';
import { formatDate } from '@utils/formatters';

import {
   clientCardStyles,
   clientCardTitleStyles,
   clientCardDateStyles,
   clientCardListStyles,
   clientCardListItemStyles,
   clientCardListItemTextStyles,
} from './style';

import { colorsStatus } from '@constants/styles/colorsStatus';
import { StatusClient } from '@enums/clients';
import { statusRunTimeArr } from '@constants/status';

export const ClientCard: FC<Partial<ClientCardProps>> = ({
   dateStart,
   done,
   lastStage,
   manager,
   idScript,
   objections,
   status,
}) => {
   const theme = useTheme();

   const statusColor = getStatusColorLeadRuntime({
      colorsArr: colorsStatus,
      status: status as StatusClient,
   });

   const statusText = getStatusNameLeadRuntime({
      status: status as StatusClient,
      statusNameArr: statusRunTimeArr,
   });

   const formatedDate = formatDate(dateStart as string);

   return (
      <Box sx={{ ...clientCardStyles() }}>
         <Box>
            <Typography sx={{ ...clientCardTitleStyles() }} variant="body2">
               Скрипт №{idScript}
            </Typography>
            <Typography
               sx={{
                  ...clientCardListItemTextStyles(),
                  ...(clientCardDateStyles() as object),
               }}
               variant="overline"
            >
               {formatedDate}
            </Typography>
         </Box>
         <List sx={{ ...clientCardListStyles() }}>
            <ListItem sx={{ ...clientCardListItemStyles() }}>
               <Typography sx={{ ...clientCardListItemTextStyles() }} variant="body1">
                  Статус:
               </Typography>
               <Typography sx={{ color: statusColor }} variant="body2">
                  {statusText}
               </Typography>
            </ListItem>

            <ListItem sx={{ ...clientCardListItemStyles() }}>
               <Typography sx={{ ...clientCardListItemTextStyles() }} variant="body1">
                  Возражений:
               </Typography>
               <Typography variant="body2">{objections}</Typography>
            </ListItem>

            <ListItem sx={{ ...clientCardListItemStyles() }}>
               <Typography sx={{ ...clientCardListItemTextStyles() }} variant="body1">
                  Отработанно:
               </Typography>
               <Typography variant="body2">{done}</Typography>
            </ListItem>

            <ListItem sx={{ ...clientCardListItemStyles() }}>
               <Typography sx={{ ...clientCardListItemTextStyles() }} variant="body1">
                  Последняя стадия:
               </Typography>
               <Typography variant="body2">{lastStage}</Typography>
            </ListItem>

            <ListItem sx={{ ...clientCardListItemStyles() }}>
               <Typography sx={{ ...clientCardListItemTextStyles() }} variant="body1">
                  Менеджер:
               </Typography>
               <Typography variant="body2">{manager}</Typography>
            </ListItem>
         </List>
      </Box>
   );
};
