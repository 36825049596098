import React, { FC } from 'react';

import { useTheme } from '@hooks/useTheme';

import { Box } from '@mui/material';
import { Typography } from '@mui/material';

import type { ProfileHeaderProps } from './type';
import { profileHeaderTitleStyle, profileHeadereStyle } from './style';

export const ProfileHeader: FC<ProfileHeaderProps> = ({
   title,
   children,
   styles,
   sx,
   stylesText,
   sxText,
   IconText,
   direction = 'after',
}) => {
   const { theme } = useTheme();

   const additionalStyles = typeof styles === 'function' || sx ? sx : styles;

   return (
      <Box
         sx={{
            ...profileHeadereStyle(theme),
            ...(additionalStyles ? additionalStyles : {}),
         }}
      >
         <Typography
            sx={{
               ...profileHeaderTitleStyle(theme),
               ...stylesText,
               ...((sxText ?? {}) as object),
            }}
         >
            {title}
         </Typography>
         {children}
      </Box>
   );
};
