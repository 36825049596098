import React, { useState } from 'react';

import { useToggleModal } from '@hooks/hooksUI';
import { useRuntimeLogsData } from '@hooks/hooksApi/logs';

import { ColumnDashboard } from '@shared/column';
import { LogsNoneData } from '../logsNoneData';
import { LogsData } from '../logsData';
import { LoaderDefault } from '@shared/loader';
import { ErrorMessage } from '@shared/errorMessage';
import { LogsFilters } from '../logsFilters';
import { ProfileHeader } from '@componentsDashBoard/profileHeader';
import { ButtonAction } from '@shared/buttons';

import { ReactComponent as DownloadIcon } from '@assets/icons/download.svg';

export const Logs = () => {
   const [page, setPage] = useState(1);

   const {
      runtimeLogIsLoading,
      runtimeLogData,
      runtimeLogIsError,
      runtimeLogIsSuccess,
      runtimeLogIsFetching,
   } = useRuntimeLogsData({ page });

   const { isOpen: isOpenModalFilters, toggle: toggleModalFilters } = useToggleModal();

   const inNoneDate = runtimeLogData?.data?.length === 0;

   if (runtimeLogIsError) return <ErrorMessage message="ошибка" />;

   return (
      <ColumnDashboard gap="32px">
         <ProfileHeader title="Логи">
            <ButtonAction disabled={inNoneDate} Icon={DownloadIcon}>
               Выгрузить в CVS
            </ButtonAction>
         </ProfileHeader>

         {inNoneDate ? (
            <LogsNoneData />
         ) : runtimeLogIsLoading ? (
            <LoaderDefault stylesInner={{ justifyContent: 'center' }} />
         ) : (
            <>
               <LogsFilters onClickOpenModal={toggleModalFilters} />

               <LogsData
                  isFetching={runtimeLogIsFetching}
                  page={page}
                  setPage={setPage}
                  data={runtimeLogData!}
               />
            </>
         )}
      </ColumnDashboard>
   );
};
