import React, { FC } from 'react';
import { Box, Pagination, Stack, Theme, useMediaQuery } from '@mui/material';

import { PaginationButtonItem } from '../paginationButtonItem';

import type { PagonationBodyProps } from './type';

import { paginationButtonStyles } from './style';
import { useTheme } from '@hooks/useTheme';

export const PaginationButton: FC<PagonationBodyProps> = ({
   meta,
   setCurrentPage,
   currentPage,
   disabled,
}) => {
   const prevButton = [...meta.links].shift();
   const nextButton = [...meta.links].pop();

   const theme = useTheme();
   const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

   const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
      setCurrentPage(page);
   };

   if (meta.last_page === 1) return null;

   return (
      <Box sx={{ ...paginationButtonStyles(theme) }}>
         <Stack spacing={'2px'}>
            <Pagination
               siblingCount={isMobile ? 0 : 1}
               count={meta.last_page}
               page={currentPage}
               onChange={handlePageChange}
               renderItem={(item) => {
                  return (
                     <PaginationButtonItem
                        disabled={disabled}
                        item={item}
                        currentPage={currentPage}
                        lastPage={meta.last_page}
                        onChangePage={handlePageChange}
                        nextButtonText={nextButton?.label}
                        prevButtonText={prevButton?.label}
                     />
                  );
               }}
               variant="outlined"
               shape="rounded"
            />
         </Stack>
      </Box>
   );
};
