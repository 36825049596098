import type { OnClickDeleteLeadParams } from './type';

export const onClickDeleteLead = async ({
   idLead,
   leadDelete,
   refetch,
   toggleModal,
   toggleAlert,
}: OnClickDeleteLeadParams) => {
   try {
      await leadDelete(idLead);
      refetch();
      toggleModal();
   } catch (err) {
      console.error(err);
      toggleAlert();
   }
};
