import { IProjectCustomFieldsDataItem } from '@projectTypes/projects';

export const runtimeAdditionalFields: IProjectCustomFieldsDataItem[] = [
   {
      id: -1,
      name: 'Имя',
      slug: 'nameDefault',
      type: 'string',
      created_at: '-',
      updated_at: '-',
      required: true,
   },
   {
      id: 0,
      name: 'Номер телефона',
      slug: 'phoneNuberDefault',
      type: 'tel',
      created_at: '-',
      updated_at: '-',
      required: true,
   },
];
