import { SxProps, Theme } from '@mui/material';

import { colorsUI } from '@constants/colorsUI';

const { black500, black100 } = colorsUI;

export const clientCardStyles = (): SxProps<Theme> => ({
   display: 'flex',
   flexDirection: 'column',
   gap: '10px',
});

export const clientCardTitleStyles = (): SxProps<Theme> => ({
   fontWeight: 700,
   fontFamily: 'Exo2-Bold',
   color: black500,
});

export const clientCardDateStyles = (): SxProps<Theme> => ({
   marginTop: '4px',
});

export const clientCardListStyles = (): SxProps<Theme> => ({
   display: 'flex',
   flexDirection: 'column',
   gap: '10px',
});

export const clientCardListItemStyles = (): SxProps<Theme> => ({
   display: 'flex',
   gap: '10px',
   padding: '0',
});

export const clientCardListItemTextStyles = (): SxProps<Theme> => ({
   color: black100,
   fontFamily: 'Exo2-italic',
});

// export const clientCardListItemValueStyles = (): SxProps<Theme> => ({
//    color: black100,
//    fontFamily: 'Exo2-italic',
// });
