import { RuntimeFieldsErrorsType } from '../../../../../types/fieldsType';
import type { ValidateFormNoneLeadParams } from './type';

export const validateFormIsNoneLead = ({
   formValues,
   setErrors,
}: ValidateFormNoneLeadParams): boolean => {
   const errors = {} as RuntimeFieldsErrorsType;

   console.log(formValues.nameDefault);

   if (formValues.nameDefault?.length && formValues.nameDefault?.length < 2) {
      errors.nameDefault = 'Имя должно содержать минимум 2 буквы';
   }
   if (
      (formValues?.phoneNuberDefault?.phoneNumber?.length &&
         formValues?.phoneNuberDefault?.phoneNumber?.length < 5) ||
      !formValues.phoneNuberDefault?.countryCode
   ) {
      errors.phoneNuberDefault = 'Не коректный номер телефона';
   }
   if (!formValues.phoneNuberDefault?.countryCode) {
      errors.phoneNuberDefault = 'Не коректный номер телефона';
   }

   setErrors(errors);

   console.log(errors);

   return Object.keys(errors).length === 0;
};
