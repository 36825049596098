import React, { FC } from 'react';
import Alert from '@mui/material/Alert';

import type { AlertDefaultProps } from '../types';

export const AlertDefault: FC<AlertDefaultProps> = ({
   severity = 'success',
   message,
   sx,
   sxIcon,
   styles,
   stylesIcon,
   Icon,
   variant = 'standard',
}) => {
   return (
      <Alert
         variant={variant}
         sx={{ ...((sx ?? {}) as object), ...(styles as object) }}
         icon={
            Icon && (
               <Icon
                  sx={{ ...((sxIcon ?? {}) as object), ...(stylesIcon as object) }}
                  fontSize="inherit"
               />
            )
         }
         severity={severity}
      >
         {message}
      </Alert>
   );
};
