import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiDashboard, apiRuntimeLog } from '@constants/api/endpoints';

import type { ILogsResponse, ILogsHistoryResponse } from '@projectTypes/logs';

const baseUrl = process.env.REACT_APP_API;

export const runtimeLogApi = createApi({
   reducerPath: 'runtimeLogApi',
   baseQuery: fetchBaseQuery({
      baseUrl: `${baseUrl}${apiDashboard}`,
      prepareHeaders: (headers) => {
         headers.set('Content-Type', 'application/json');
         headers.set('Accept', 'application/json');
         const token = document.cookie
            .split('; ')
            .find((row) => row.startsWith('authToken='))
            ?.split('=')[1];
         if (token) {
            headers.set('Authorization', `Bearer ${token}`);
         }
         return headers;
      },
   }),
   tagTypes: ['Logs'],
   endpoints: (builder) => ({
      runtimeLog: builder.query<ILogsResponse, { query?: string; page?: number }>({
         query: ({ query, page }) => ({
            url: `${apiRuntimeLog}?page=${page ?? 1}`,
         }),
         providesTags: (result) =>
            result ? [{ type: 'Logs', id: 'LIST' }] : [{ type: 'Logs', id: 'LIST' }],
      }),
      runtimeLogByUuid: builder.query<ILogsHistoryResponse, { uuid: string }>({
         query: ({ uuid }) => ({
            url: `${apiRuntimeLog}/${uuid}`,
         }),
         providesTags: (result) =>
            result ? [{ type: 'Logs', id: 'LIST' }] : [{ type: 'Logs', id: 'LIST' }],
      }),
   }),
});

export const { useRuntimeLogQuery, useRuntimeLogByUuidQuery } = runtimeLogApi;
