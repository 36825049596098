import { SxProps, Theme } from '@mui/material';

export const paginationButtonStyles = ({ theme }: { theme: Theme }): SxProps<Theme> => ({
   width: '100%',
   display: 'flex',
   justifyContent: 'start',
});

export const paginationButtonStackStyles = ({
   theme,
}: {
   theme: Theme;
}): SxProps<Theme> => ({
   display: 'flex',
   gap: '2px',
});
