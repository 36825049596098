export const calculateWorkTimeInMinutes = (startTime: string, endTime: string) => {
   const start = new Date(startTime.replace(' ', 'T'));
   const end = new Date(endTime.replace(' ', 'T'));

   // Проверяем, чтобы конец был позже начала
   if (end.getTime() < start.getTime()) {
      throw new Error('End time must be later than start time.');
   }

   // Разница в миллисекундах
   const diff = end.getTime() - start.getTime();

   // Вычисляем полные секунды
   const totalSeconds = Math.floor(diff / 1000);
   const hours = Math.floor(totalSeconds / 3600);
   const minutes = Math.floor((totalSeconds % 3600) / 60);
   const seconds = totalSeconds % 60;

   return `${hours}:${minutes}:${seconds}`;
};
