import React from 'react';

import { Typography } from '@mui/material';
import { LinkDefault } from '@shared/links';
import { NotData } from '@componentsDashBoard/notData';

import imagePath from '@assets/images/menegers.png';
import { ReactComponent as PlusIcon } from '@assets/icons/plus.svg';

import styles from './styles/style.module.css';
import { managersNoneButtonStyles, managersNoneTypographyStyles } from './styles/styles';

const { image } = styles;

export const ManagersNone = () => {
   return (
      <NotData>
         <img src={imagePath} className={image} alt="not menegers" />
         <Typography sx={managersNoneTypographyStyles} variant="body1">
            У вас пока нет добавленных менеджеров
         </Typography>
         <LinkDefault
            href="/projects/create-projects"
            sx={managersNoneButtonStyles as object}
            Icon={PlusIcon}
            label="Добавить менеджера"
         />
      </NotData>
   );
};
