import type { CompareObjectsParams } from './type';

export const compareObjects = <T>({
   objFirst,
   objLast,
}: CompareObjectsParams<T>): boolean => {
   for (const key in objFirst) {
      const firstEl = objFirst[key];
      const lastEl = objLast[key];
      if (firstEl !== lastEl) return false;
   }

   return true;
};
