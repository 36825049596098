import { validateFormIsNoneLead } from '../validateFormIsNoneLead';
import { removeCountryCode } from '@utils/phone';

import type { IRuntimeSaveLeadDataRequets } from '@projectTypes/runtime';
import type { OnSubmitFormIsNoneLeadParams } from './type';
import { removeFalsyValuesInObject } from '@utils/objectUtils';

export const onSubmitFormIsNoneLead = async ({
   formValues,
   setErrors,
   dataStart,
   saveLeadData,
   setIsOpenAlert,
}: OnSubmitFormIsNoneLeadParams) => {
   const isNotHasErrors = validateFormIsNoneLead({ formValues, setErrors });

   console.log(isNotHasErrors);

   if (isNotHasErrors) {
      const code = formValues.phoneNuberDefault?.countryCode as string;
      const phone = formValues.phoneNuberDefault?.phoneNumber as string;

      const { phoneNuberDefault, nameDefault, ...fields } = formValues;

      const obj = {
         name: formValues.nameDefault,
         phone_code: formValues.phoneNuberDefault?.countryCode,
         phone_number: removeCountryCode({ code, phone }),
         fields: removeFalsyValuesInObject(fields),
      } as IRuntimeSaveLeadDataRequets;

      try {
         await saveLeadData(dataStart, obj);
      } catch (err) {
         console.error(err);
      }
      setIsOpenAlert(true);
   } else {
      setIsOpenAlert(false);
   }
};
