import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { ILeadResponse } from '@projectTypes/lead';

const initialState: ILeadResponse = {
   data: null,
   links: null,
   meta: null,
};

const leadSlice = createSlice({
   name: 'leadle',
   initialState,
   reducers: {
      setLeadData(state, action: PayloadAction<ILeadResponse>) {
         return action.payload;
      },
   },
});

export const { setLeadData } = leadSlice.actions;

export default leadSlice.reducer;
