import React, { useState, FC } from 'react';

import { useTheme } from '@hooks/useTheme';

import { useProjectCustomFieldsQuery } from 'app/dashboard/store/api/projectApi';
import { Box, Typography } from '@mui/material';
import { VisualScriptFormTextField } from './components';
import { LoaderDefault } from '@shared/loader';

import { useSaveLead } from '@hooks/hooksApi/useSaveLead';
import { onBlurVisualScript } from '../../../utils';
import { IRuntimeSaveLeadDataRequets } from '@projectTypes/runtime';

import type { OnBlurDataType } from '../../../types';
import type { IProjectCustomFieldsDataItem } from '@projectTypes/projects';
import type { VisualScriptFormProps } from './type';

import { visualScriptFormStyles, visualScriptFormFormStyles } from './styles';

export const VisualScriptForm: FC<VisualScriptFormProps> = ({ dataStart }) => {
   console.log(dataStart);
   const { theme } = useTheme();

   const [dataLocal, setDataLocal] = useState<IRuntimeSaveLeadDataRequets | {}>({});

   const { data, isLoading, isError } = useProjectCustomFieldsQuery({});
   const { saveLeadData } = useSaveLead();

   const onBlur = (data: OnBlurDataType) => {
      console.log(data);
      console.log(dataLocal);
      onBlurVisualScript(data, dataStart, setDataLocal, saveLeadData);
   };

   console.log(data?.data);

   if (isError) return <p style={{ color: 'red' }}>Error</p>;

   if (isLoading) return <LoaderDefault />;

   return (
      <Box sx={{ ...visualScriptFormStyles(theme) }}>
         <Typography variant="subtitle2">Данные</Typography>
         <Box sx={visualScriptFormFormStyles}>
            {data &&
               data?.data.map((item: IProjectCustomFieldsDataItem) => (
                  <VisualScriptFormTextField
                     name={item.name}
                     onBlur={onBlur}
                     id={item.id}
                     placeholder={item.name}
                     key={item.id}
                     slug={item.slug}
                     type={item.type}
                  />
               ))}
         </Box>
      </Box>
   );
};
