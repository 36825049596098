import { SxProps, Theme } from '@mui/material';

import { colorsUI } from '@constants/colorsUI';

const { light, light200, light400, light800, transperent, black50, black100 } = colorsUI;

export const paginationButtonItemButtonStyles = ({
   isActive = false,
}: {
   isActive?: boolean;
}): SxProps<Theme> => ({
   fontSize: '1rem',
   padding: '0 10px',

   '&.Mui-selected': {
      background: isActive ? light400 : transperent,
      '&:hover': {
         background: light800,
         color: light,
      },
   },
   '&.Mui-disabled': {
      color: black100,
      background: black50,
      pointerEvents: 'none',
      borderColor: black50,
   },
   border: `1px solid ${isActive ? light400 : light200}`,
   color: isActive ? light : light800,
   '&:hover': {
      background: light800,
      color: light,
   },
});
