import React, { FC } from 'react';

import { PaginationItem } from '@mui/material';

import type { ButtonPaginationProps } from '../types/buttonPaginationType';

export const ButtonPagination: FC<ButtonPaginationProps> = ({
   onClick,
   item,
   currentPage,
   disabled = false,
   sx,
   Component,
}) => {
   return (
      <>
         {Component ? (
            <Component />
         ) : (
            <PaginationItem
               {...item}
               onClick={(e) => onClick(e, currentPage)}
               disabled={disabled}
               sx={{ ...((sx ?? {}) as object) }}
            ></PaginationItem>
         )}
      </>
   );
};
