import React, { FC, useState, MouseEvent } from 'react';

import { PaginationButton } from '@services/pagination';

import type { LogsDataProps } from './type';
import type { ILogsData } from '@projectTypes/logs';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import { TableCheckbox, TableCheckboxMobile } from '@shared/tables';
import { OverlayLoading } from '@shared/overlay';
import { LinkDefault } from '@shared/links';

import { statusLogsArr } from '@constants/status';
import { colorsStatus } from '@constants/styles/colorsStatus';

import { colorsUI } from '@constants/colorsUI';
import { ColumnDashboard } from '@shared/column';

const { transperent, light900 } = colorsUI;

export const LogsData: FC<LogsDataProps> = ({ data, page, setPage, isFetching }) => {
   const [selected, setSelected] = useState<(number | string)[]>([]);
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const [popupIsOpen, setPopupIsOpen] = useState(false);
   const [rowState, setRowState] = useState<string>('');

   const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

   const handleClickPopup = (event: MouseEvent<HTMLElement>, row: string) => {
      setAnchorEl(event.currentTarget);
      setPopupIsOpen(true);
   };

   const columns: any = [
      { id: 'lead_id', label: 'ID клиента' },
      { id: 'manager_id', label: 'ID менеджера' },
      { id: 'name', label: 'ФИО' },
      { id: 'phone', label: 'Телефон' },
      { id: 'start', label: 'Время запуска' },
      { id: 'project_name', label: 'Имя проекта' },
      {
         id: 'status',
         label: 'Статус',
         renderCell: (row: ILogsData) => {
            const indexColor = statusLogsArr.indexOf(row.status);
            const color = colorsStatus[indexColor];

            return (
               <Typography sx={{ color }} variant="body2">
                  {row.status}
               </Typography>
            );
         },
      },

      { id: 'finished_at', label: 'Время окончания' },
      { id: 'source', label: 'Источник' },
      { id: 'manager', label: 'Менеджер' },
      {
         id: 'actions',
         label: 'история логов',
         renderCell: ({ uuid }: ILogsData) => {
            return (
               <LinkDefault
                  color={light900}
                  backgroundColor={transperent}
                  backgroundColorHover={transperent}
                  href={`/logs/${uuid}`}
               >
                  Открыть
               </LinkDefault>
            );
         },
      },
   ];

   return (
      <ColumnDashboard gap="32px">
         {isMobile ? (
            <TableCheckboxMobile
               customId="uuid"
               columns={columns}
               rows={data?.data as ILogsData[]}
               selected={selected}
               onSelect={setSelected}
            />
         ) : (
            <OverlayLoading isOpen={isFetching}>
               <TableCheckbox
                  customId="uuid"
                  columns={columns}
                  rows={data?.data as ILogsData[]}
                  selected={selected}
                  onSelect={setSelected}
               />
            </OverlayLoading>
         )}
         <PaginationButton
            disabled={isFetching}
            currentPage={page}
            meta={data.meta}
            setCurrentPage={setPage}
         />
      </ColumnDashboard>
   );
};
