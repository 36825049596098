import { SetStateAction, Dispatch } from 'react';

import type { OnBlurDataType } from 'app/dashboard/modules/visual-script/types';

type onChangeParams = {
   isActive: boolean;
   setIsActive: Dispatch<SetStateAction<boolean>>;
   data: OnBlurDataType;
   onBlur: (data: OnBlurDataType) => void;
   setIsOpenTooltip: Dispatch<SetStateAction<boolean>>;
};

export const onHandleBlurField = ({
   isActive,
   setIsActive,
   setIsOpenTooltip,
   data,
   onBlur,
}: onChangeParams): void => {
   if (isActive) {
      onBlur(data);
      setIsActive(false);
      setIsOpenTooltip(false);
   } else {
      setIsOpenTooltip(true);
   }
};
