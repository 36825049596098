import React, { FC } from 'react';
import { SxProps, Theme, useMediaQuery } from '@mui/material';

import type { HistoryScriptHeaderProps } from './type';
import { GridDefault } from '@shared/grid';

import { historyScriptData } from './data';
import { GridData } from '@shared/grid/types';
import { statusLogsArr } from '@constants/status';
import { colorsStatus } from '@constants/styles/colorsStatus';

export const HistoryScriptHeader: FC<HistoryScriptHeaderProps> = ({
   uuid,
   status,
   dataFinish,
   dataStart,
}) => {
   const indexColor = statusLogsArr.indexOf(status);
   const color = colorsStatus[indexColor];

   const data: GridData[] = historyScriptData({
      data: { uuid, status, dataFinish, dataStart },
      color,
   });

   const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

   return (
      <>
         <GridDefault display={isMobile ? 'flex' : 'grid'} data={data} />

         {/* {uuid} <br />
         {status} <br />
         {dataFinish} <br />
         {dataStart} <br /> */}
      </>
   );
};
