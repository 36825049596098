import { SxProps, Theme } from '@mui/material';

import { colorsUI } from '@constants/colorsUI';

const { dangerBg, danger, smokeGray, black100, black300, black500 } = colorsUI;

export const historyScriptItemStyles = ({
   theme,
   isClient = false,
   isObjection = false,
}: {
   theme: Theme;
   isClient?: boolean;
   isObjection?: boolean;
}): SxProps<Theme> => ({
   width: '100%',
   display: 'flex',
   flexDirection: 'column',
   gap: '24px',
   alignItems: isClient ? 'flex-end' : 'flex-start',
   '& div': {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      width: '100%',
      maxWidth: '70%',
      background: isObjection ? dangerBg : smokeGray,
      border: `1px solid ${isObjection ? danger : black100}`,
      borderRadius: '6px',
      padding: '16px',
      [theme.breakpoints.down('sm')]: {
         maxWidth: 'none',
      },
   },
});

export const historyScriptItemReactionTypeStyles = ({
   theme,
   isNegative = false,
}: {
   theme: Theme;
   isNegative?: boolean;
}): SxProps<Theme> => ({
   color: isNegative ? danger : black500,
   fontWeight: 700,

   lineHeight: '1.625rem',
});

export const historyScriptItemDescStyles = ({
   theme,
}: {
   theme: Theme;
}): SxProps<Theme> => ({
   fontWeight: 400,
   color: black300,
});
