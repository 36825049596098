import React, { FC, MouseEvent, useMemo, useRef, useState } from 'react';

import { useTheme } from '@hooks/useTheme';
import { Paper, useMediaQuery } from '@mui/material';

import { Box } from '@mui/material';
import { TableDefault, TableMobile } from '@shared/tables';
import { InputSearch } from '@shared/inputs';
import { PopupDefault } from '@shared/popups';
import { ListPopupActionContents } from 'shared/lists';
import { TableActionsButtonMoreOptions } from 'components/tableActions/tableActionsButtonMoreOptions';

import { ModalDelete } from '@shared/modals';
import { ListItemLink } from '@shared/listItems';
import { PaginationButton } from '@services/pagination';
import { OverlayLoading } from '@shared/overlay';

import { ReactComponent as IconPerson } from '@assets/icons/personIcon.svg';
import { ReactComponent as IconMail } from '@assets/icons/mail.svg';
import { ReactComponent as IconAnalytics } from '@assets/icons/analyticsPopupIcon.svg';
import { ReactComponent as IconLogs } from '@assets/icons/logsIcon.svg';
import { ReactComponent as IconDelete } from '@assets/icons/deleteKrest.svg';

import type { IProjectTable } from '@projectTypes/projects';
import type { ManagersDataProps } from './type';
import type { ListPopupActionContentItemsProps } from '@shared/lists/types';

import {
   managersDataStyles,
   managersDataFilterStyles,
   managersDataFilterTextFieldStyles,
} from './styles';

export const ManagersData: FC<ManagersDataProps> = ({
   dataManager,
   isFetching,
   isLoading,
}) => {
   const [popupIsOpen, setPopupIsOpen] = useState(false);
   const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);

   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const [rowState, setRowState] = useState<string>('');

   const [page, setPage] = useState(1);

   const handleClickPopup = (event: MouseEvent<HTMLElement>, row: string) => {
      setAnchorEl(event.currentTarget);
      setPopupIsOpen(true);
      setRowState(row);
   };

   const handleClosePopup = () => {
      setAnchorEl(null);
      setPopupIsOpen(false);
   };

   const handleOpenDeleteModal = () => {
      setAnchorEl(null);
      setPopupIsOpen(false);
      setModalDeleteIsOpen(true);
   };

   const handleCloseDeleteModal = () => {
      setModalDeleteIsOpen(false);
   };

   const handleOnDelete = () => {
      setModalDeleteIsOpen(false);
   };

   const popupActionContentItems: ListPopupActionContentItemsProps[] = useMemo(
      () => [
         {
            title: 'Перейти в профиль',
            onClick: () => alert('Перейти в профиль'),
            Icon: IconPerson,
         },
         {
            title: 'Отправить приглашение',
            onClick: () => alert('Отправить приглашение'),
            Icon: IconMail,
            disable: true,
         },
         {
            title: 'Аналитика',
            onClick: () => alert('Аналитика'),
            Icon: IconAnalytics,
         },
         {
            title: 'Логи',
            onClick: () => alert('Логи'),
            Icon: IconLogs,
         },
         {
            title: 'Удалить',
            onClick: handleOpenDeleteModal,
            Icon: IconDelete,
            error: true,
         },
      ],
      [handleClickPopup],
   );

   const reactListTest = [
      <ListItemLink href="/" label="test1" />,
      <ListItemLink href="/" label="test2" />,
      <ListItemLink href="/" label="test3" />,
      <ListItemLink href="/" label="test4" />,
   ];

   const columns: any = [
      { id: 'email', label: 'Почта' },
      { id: 'fio', label: 'ФИО менеджера' },
      { id: 'projects', label: 'Проекты' },
      { id: 'count-start', label: 'Кол-во запусков' },
      { id: 'count-start-successes', label: 'Кол-во успешных' },
      { id: 'conversion', label: 'Конверсия' },
      { id: 'Invitation', label: 'Приглашение' },
      { id: 'validity period', label: 'Срок действия' },

      {
         id: 'actions',
         label: 'Действия',
         renderCell: (row: any) => (
            <TableActionsButtonMoreOptions
               text="Редактировать"
               onClick={(e) => handleClickPopup(e as MouseEvent<HTMLButtonElement>, row)}
            />
         ),
      },
   ];

   const { theme } = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

   const inputRef = useRef<HTMLInputElement>(null);

   return (
      <Box sx={managersDataStyles}>
         <ModalDelete
            open={modalDeleteIsOpen}
            onClose={handleCloseDeleteModal}
            onDelete={handleOnDelete}
            title="Удаление менеджера"
            text="Вы уверены, что хотите уволить менеджера? Вся аналитика и логи менеджера будут удалены"
         />
         <PopupDefault
            styles={{ padding: 0 }}
            handleClose={handleClosePopup}
            open={popupIsOpen}
            anchorEl={anchorEl}
         >
            <ListPopupActionContents
               styles={{ padding: 0 }}
               items={popupActionContentItems}
            />
         </PopupDefault>
         <Box sx={managersDataFilterStyles}>
            <InputSearch
               stylesForm={managersDataFilterTextFieldStyles(theme) as object}
               stylesWrapper={managersDataFilterTextFieldStyles(theme) as object}
               inputRef={inputRef}
               placeholder="Поиск"
            />
         </Box>
         <OverlayLoading
            sxInner={{ overflow: 'hidden', width: '100%' }}
            isOpen={isFetching}
         >
            {isMobile ? (
               <TableMobile
                  columns={columns}
                  rows={dataManager?.data as unknown as IProjectTable[]}
               />
            ) : (
               <TableDefault
                  columns={columns}
                  rows={dataManager?.data as unknown as IProjectTable[]}
               />
            )}
         </OverlayLoading>

         <PaginationButton
            disabled={isLoading || isFetching}
            currentPage={page}
            setCurrentPage={setPage}
            meta={dataManager.meta}
         />
      </Box>
   );
};
