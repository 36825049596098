import { useProjectCustomFieldsQuery } from 'app/dashboard/store/api/projectApi';
import type { IErrorResponseSimplified } from '@projectTypes/apiTypes';

export const useProjectCustomFields = ({ id }: { id?: number }) => {
   const {
      data: projectCustomFieldsData,
      isSuccess: projectCustomFieldsIsSuccess,
      isLoading: projectCustomFieldsIsLoading,
      isFetching: projectCustomFieldsIsFetching,
      isError: projectCustomFieldsIsError,
      refetch: refetch,
      error: errorMessage,
   } = useProjectCustomFieldsQuery({ id });

   return {
      projectCustomFieldsData,
      projectCustomFieldsIsLoading,
      projectCustomFieldsIsFetching,
      projectCustomFieldsIsError,
      projectCustomFieldsIsSuccess,
      refetch,
      errorMessage: errorMessage as IErrorResponseSimplified,
   };
};
