import React, { useState, MouseEvent, useMemo, FC, useEffect } from 'react';

import { useLeadDelete } from '@hooks/hooksApi';
import { useToggleModal } from '@hooks/hooksUI';
import {
   useManagerIdProjectsQuery,
   useManagerIdQuery,
} from 'app/dashboard/store/api/managerApi';
import { useManagerAttachProjects } from '@hooks/hooksApi/useManagerAttachProjects';

import { PopupDefault } from '@shared/popups';
import { TableCheckbox, TableCheckboxMobile } from '@shared/tables';
import { ListMore, ListPopupActionContents } from '@shared/lists';
import { TableActionsButtonMoreOptions } from 'components/tableActions';
import { ReactComponent as IconEdit } from '@assets/icons/edit.svg';
import { ButtonActionsBox } from './components';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { ModalDelete } from '@shared/modals';
import { ClientCard } from './components/clientCard';
import { PaginationButton } from '@services/pagination';
import { AlertSnackbar } from '@shared/alert';
import { OverlayLoading } from '@shared/overlay';

import { formatDate } from '@utils/formatters';
import { mergePhoneCodeWithPhoneNumber } from '@utils/phone';

import type { ClientsDataProps } from './type';
import type { ILeadData } from '@projectTypes/lead';
import type { ListPopupActionContentItemsProps } from '@shared/lists/types';
import type { IBaseMeta } from '@projectTypes/apiTypes';

import { onClickDeleteLead } from './utils';

import { clientsDataTableStyles } from './style';

export const ClientsData: FC<ClientsDataProps> = ({
   dataLead,
   refetch,
   page,
   setPage,
   isFetching = false,
   isLoading = false,
}) => {
   const [selected, setSelected] = useState<(number | string)[]>([]);
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const [popupIsOpen, setPopupIsOpen] = useState(false);

   const {
      isOpen: isOpenAlert,
      setIsOpen: setIsOpenAlert,
      toggle: toggleAlert,
   } = useToggleModal();
   const { isOpen: isOpenModalDelete, toggle: toggleModalDelete } = useToggleModal();

   const { leadDeleteData, isError, error, leadDeleteIsLoading } = useLeadDelete();
   const {
      attachProjectData,
      error: errorManager,
      isError: isErrorManager,
      isLoading: isLoadingManager,
   } = useManagerAttachProjects();

   const onClickAppointManager = async () => {
      const id = selected[0];
      await attachProjectData(id as string);
   };

   const onClickDelete = async () => {
      try {
         await onClickDeleteLead({
            idLead: selected[0] as string,
            leadDelete: leadDeleteData,
            refetch,
            toggleModal: toggleModalDelete,
            toggleAlert: toggleAlert,
         });
      } finally {
         setSelected([]);
      }
   };

   const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

   const handleClickPopup = (event: MouseEvent<HTMLElement>, row: string) => {
      setAnchorEl(event.currentTarget);
      setPopupIsOpen(true);
   };

   const popupActionContentItems: ListPopupActionContentItemsProps[] = useMemo(
      () => [
         {
            title: 'Аналитика',
            onClick: () => alert('Аналитика'),
            Icon: IconEdit,
         },
         {
            title: 'Логи',
            onClick: () => alert('Логи'),
            Icon: IconEdit,
         },
         {
            title: 'Уволить',
            onClick: () => alert('Уволить'),
            Icon: IconEdit,
            error: true,
         },
      ],
      [handleClickPopup],
   );

   const handleClosePopup = () => {
      setAnchorEl(null);
      setPopupIsOpen(false);
   };

   const columns: any = [
      { id: 'id', label: 'ID' },
      {
         id: 'name',
         label: 'ФИО клиента',
         renderCell: (row: ILeadData) => {
            const cellRuntimes = row.runtimes.map((el) => (
               <ClientCard
                  objections={el.interactions_count as number}
                  idScript={el.id}
                  status={el.status}
                  dateStart={el.updated_at}
               />
            ));
            const cellName = <Typography variant="body2">{row.name}</Typography>;

            const cellClientInfo = React.Children.toArray([cellName, ...cellRuntimes]);

            return (
               <ListMore
                  variant="caption"
                  isIconNone
                  textOpen="Показать больше"
                  countVisibleElements={1}
                  items={cellClientInfo}
               />
            );
         },
      },
      {
         id: 'phone_number',
         label: 'Телефон',
         isNoneRenderCell: true,

         renderCell: ({ phone_code, phone_number }: ILeadData) => {
            const phoneNumber = mergePhoneCodeWithPhoneNumber({
               code: phone_code,
               phoneNumber: phone_number,
            });
            return <Typography variant="body2">{phoneNumber}</Typography>;
         },
      },
      {
         id: 'created_at',
         label: 'Дата добавления',
         isNoneRenderCell: true,

         renderCell: (row: ILeadData) => {
            return <Typography variant="body2">{formatDate(row.updated_at)}</Typography>;
         },
      },
      {
         id: 'source',
         label: 'Источник',
         isNoneRenderCell: true,

         renderCell: (row: ILeadData) => {
            return <Typography variant="body2">{row.source}</Typography>;
         },
      },

      {
         id: 'action',
         label: 'Дейстиве',
         renderCell: (row: any) => (
            <TableActionsButtonMoreOptions
               text="Запустить"
               onClick={(e) => handleClickPopup(e as MouseEvent<HTMLButtonElement>, row)}
            />
         ),
      },
   ];

   return (
      <>
         <AlertSnackbar
            severity={isError ? 'error' : 'success'}
            setOpen={setIsOpenAlert}
            open={isOpenAlert}
            message={isError ? error?.data?.message : 'Клиент успешно удален!'}
         />

         <ModalDelete
            open={isOpenModalDelete}
            onClose={toggleModalDelete}
            onDelete={onClickDelete}
            title="Удаление клиента"
            text="Вы уверены, что хотите удалить клиентов? 
Все логи и аналитика по клиентам будут удалены"
            disabled={isLoading}
         />
         <PopupDefault
            styles={{ padding: 0 }}
            handleClose={handleClosePopup}
            open={popupIsOpen}
            anchorEl={anchorEl}
         >
            <ListPopupActionContents
               styles={{ padding: 0 }}
               items={popupActionContentItems}
            />
         </PopupDefault>
         <Box sx={clientsDataTableStyles}>
            <ButtonActionsBox
               onClickAppointManager={onClickAppointManager}
               onClickDelete={toggleModalDelete}
            />
            <OverlayLoading isOpen={isFetching}>
               {isMobile ? (
                  <TableCheckboxMobile
                     columns={columns}
                     rows={dataLead?.data as ILeadData[]}
                     selected={selected}
                     onSelect={setSelected}
                  />
               ) : (
                  <TableCheckbox
                     sxRow={{ verticalAlign: 'baseline' }}
                     columns={columns}
                     rows={dataLead?.data as ILeadData[]}
                     selected={selected}
                     onSelect={setSelected}
                  />
               )}
            </OverlayLoading>

            <PaginationButton
               disabled={isFetching || isLoading || leadDeleteIsLoading}
               currentPage={page}
               meta={dataLead.meta as IBaseMeta}
               setCurrentPage={setPage}
            />
         </Box>
      </>
   );
};
