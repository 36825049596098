import { useRuntimeLogQuery } from 'app/dashboard/store/api/runtimeLogApi';

export const useRuntimeLogsData = ({ page }: { page?: number }) => {
   const {
      data: runtimeLogData,
      currentData,
      isLoading: runtimeLogIsLoading,
      isError: runtimeLogIsError,
      isSuccess: runtimeLogIsSuccess,
      isFetching: runtimeLogIsFetching,
   } = useRuntimeLogQuery({ page });

   console.log(currentData);

   return {
      runtimeLogData,
      runtimeLogIsLoading,
      runtimeLogIsError,
      runtimeLogIsSuccess,
      runtimeLogIsFetching,
   };
};
