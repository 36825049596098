import React, { useState, MouseEvent, useMemo, FC } from 'react';
import { useTheme } from '@hooks/useTheme';
import { useMediaQuery, Box, Typography } from '@mui/material';
import { useDeleteProjectMutation } from 'app/dashboard/store/api/projectApi';
import { useProjectData } from '@hooks/hooksApi/project/useProjectData';

import { SortButtons, SortText } from '@services/sorts';
import { TableDefault, TableMobile } from '@shared/tables';
import { PopupDefault } from '@shared/popups';
import { ListPopupActionContents } from 'shared/lists';
import { ModalDelete } from '@shared/modals';
import { TableActionsButtonMoreOptions } from 'components/tableActions';
import { PaginationButton } from '@services/pagination';

import { ReactComponent as IconPerson } from '@assets/icons/personIcon.svg';
import { ReactComponent as IconMail } from '@assets/icons/mail.svg';
import { ReactComponent as IconAnalytics } from '@assets/icons/analyticsPopupIcon.svg';
import { ReactComponent as IconLogs } from '@assets/icons/logsIcon.svg';
import { ReactComponent as IconDelete } from '@assets/icons/deleteKrest.svg';
import { ReactComponent as IconEdit } from '@assets/icons/edit.svg';

import type { IProjectTable } from '@projectTypes/projects';
import type { ColumnTable } from '@shared/tables/types';
import type { ProjectsDataProps } from './type';

import { projectsDataStyles, projectsDataFilterStyles } from './styles';
import { useNavigate } from 'react-router-dom';

import { colorsUI } from '@constants/colorsUI';
import { OverlayLoading } from '@shared/overlay';

const { success, danger } = colorsUI;

const arrValues = ['новые', 'старый'];
const arrValuesButton = ['все проекты', 'Личные проекты', 'Я менеджер'];

export const ProjectsData: FC<ProjectsDataProps> = ({
   projectData,
   isFetching,
   isLoading,
}) => {
   const [popupIsOpen, setPopupIsOpen] = useState(false);
   const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);
   const [deleteSlug] = useDeleteProjectMutation();
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const [rowState, setRowState] = useState<IProjectTable | null>(null);
   const [valueFilter, setValueFilter] = useState<string | number>(arrValues[0]);
   const [activeFilterIndex, setActiveFilterIndex] = useState(0);
   const [currentPage, setCurrentPage] = useState(projectData.meta.current_page);

   const { refetch } = useProjectData();
   const { theme } = useTheme();

   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
   const navigate = useNavigate();

   const handleClickPopup = (event: MouseEvent<HTMLElement>, row: IProjectTable) => {
      setAnchorEl(event.currentTarget);
      setPopupIsOpen(true);
      setRowState(row);
   };

   const handleClosePopup = () => {
      setAnchorEl(null);
      setPopupIsOpen(false);
   };

   const handleOpenDeleteModal = (row: IProjectTable) => {
      setAnchorEl(null);
      setPopupIsOpen(false);
      setRowState(row);
      setModalDeleteIsOpen(true);
   };
   const handleClickEdit = (slug: string) => {
      if (slug) {
         navigate(`/projects/update-projects/${slug}`);
      }
   };

   const handleClickCreate = () => {
      if (rowState) {
         navigate('constructor/' + rowState.slug);
      }
   };

   const handleCloseDeleteModal = () => {
      setModalDeleteIsOpen(false);
   };

   const handleOnDelete = async () => {
      setModalDeleteIsOpen(false);

      if (rowState) {
         try {
            localStorage.removeItem(`blocks_${rowState.slug}`);
            localStorage.removeItem(`edges_${rowState.slug}`);
            localStorage.removeItem(`stages_${rowState.slug}`);
            await deleteSlug({ slug: rowState.slug }).unwrap();
            await refetch();
         } catch (error) {
            console.error('Ошибка при удалении:', error);
         }
      }
   };

   const popupActionContentItems = useMemo(
      () => [
         {
            title: 'Перейти в профиль',
            onClick: () => alert('Перейти в профиль'),
            Icon: IconPerson,
         },
         {
            title: 'Отправить приглашение',
            onClick: () => alert('Отправить приглашение'),
            Icon: IconMail,
            disable: true,
         },
         {
            title: 'Аналитика',
            onClick: () => alert('Аналитика'),
            Icon: IconAnalytics,
         },
         {
            title: 'Логи',
            onClick: () => alert('Логи'),
            Icon: IconLogs,
         },
         {
            title: 'Удалить',
            onClick: () => rowState && handleOpenDeleteModal(rowState),
            Icon: IconDelete,
            error: true,
         },
         {
            title: 'В редактор',
            onClick: () => rowState && handleClickCreate(),
            Icon: IconEdit,
         },
         {
            title: 'Настройка',
            onClick: () => rowState && handleClickEdit(rowState.slug),
            Icon: IconEdit,
         },
      ],
      [handleClickPopup, rowState],
   );

   const columns: ColumnTable<IProjectTable>[] = [
      { id: 'name', label: 'Наименование скрипта' },
      {
         id: 'is_draft',
         label: 'Черновик',
         renderCell: (row: IProjectTable) => (
            <>
               {row.is_draft ? (
                  <Typography sx={{ color: danger }}>черновик</Typography>
               ) : (
                  <Typography sx={{ color: success }}>Нет</Typography>
               )}
            </>
         ),
      },

      { id: 'role', label: 'Роль' },
      { id: 'business_area', label: 'Бизнес область' },
      { id: 'scenario_type', label: 'Тип сценария' },
      { id: 'contact_base_type', label: 'Базовый тип контактов' },
      {
         id: 'actions',
         label: 'Действия',
         renderCell: (row: IProjectTable) => (
            <TableActionsButtonMoreOptions
               text="Запустить"
               href={`/visual-script/${row?.slug}`}
               onClick={(e) => handleClickPopup(e as MouseEvent<HTMLButtonElement>, row)}
            />
         ),
      },
   ];

   const onFilterChange = (value: string | number) => {
      setValueFilter(value);
   };

   return (
      <Box sx={projectsDataStyles}>
         <ModalDelete
            open={modalDeleteIsOpen}
            onClose={handleCloseDeleteModal}
            onDelete={handleOnDelete}
            title="Удаление проекта"
            text="Вы уверены, что хотите удалить этот проект?"
         />
         <PopupDefault
            styles={{ padding: 0 }}
            handleClose={handleClosePopup}
            open={popupIsOpen}
            anchorEl={anchorEl}
         >
            <ListPopupActionContents
               styles={{ padding: 0 }}
               items={popupActionContentItems}
            />
         </PopupDefault>

         <Box sx={projectsDataFilterStyles}>
            <SortText
               onFilterChange={onFilterChange}
               value={valueFilter}
               arrValues={arrValues}
            />

            <SortButtons
               activeIndex={activeFilterIndex}
               setActiveIndex={setActiveFilterIndex}
               arrValues={arrValuesButton}
            />
         </Box>

         <OverlayLoading
            sxInner={{ overflow: 'hidden', width: '100%' }}
            isOpen={isFetching}
         >
            {isMobile ? (
               <TableMobile
                  columns={columns}
                  rows={projectData.data as IProjectTable[]}
               />
            ) : (
               <TableDefault
                  columns={columns}
                  rows={projectData.data as IProjectTable[]}
               />
            )}
         </OverlayLoading>

         <PaginationButton
            disabled={isLoading || isFetching}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            meta={projectData.meta}
         />
      </Box>
   );
};
