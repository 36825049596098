import type { IErrorResponse } from '@projectTypes/apiTypes';
import { handleCreateProjectsFormError } from '../handleCreateProjectsFormError';
import type { HandleCreateProjectsSubmitParams } from './type';

export const handleCreateProjectsSubmit = async ({
   e,
   errorsMessage,
   formData,
   setErrors,
   onSubmit,
}: HandleCreateProjectsSubmitParams): Promise<IErrorResponse | void> => {
   e.preventDefault();

   const hasErrors = handleCreateProjectsFormError({
      setErrors,
      errorsMessage,
      formData,
   });

   if (hasErrors) return;

   try {
      await onSubmit(formData);
   } catch (error) {
      return error as IErrorResponse;
   }
};
