import React, { FC } from 'react';

import { ReactComponent as FilterIcon } from '@assets/icons/filter.svg';

import type { SortTextProps } from './type';
import { Box, Typography } from '@mui/material';

import {
   sortInnerStyles,
   sortItemsActiveStyles,
   sortItemsStyles,
   sortStyles,
   sortStylesDesc,
} from './styles';

export const SortText: FC<SortTextProps> = ({
   title = 'Сортировать по:',
   value,
   arrValues,
   onFilterChange,
}) => {
   return (
      <Box sx={sortStyles}>
         <Box>
            <FilterIcon />
         </Box>
         <Typography sx={sortStylesDesc} variant="subtitle2">
            {title}
         </Typography>
         <Box sx={sortInnerStyles}>
            {arrValues.map((el) => (
               <Typography
                  variant="subtitle2"
                  sx={value === el ? sortItemsActiveStyles : sortItemsStyles}
                  onClick={() => onFilterChange(el)}
                  key={el}
               >
                  {el}
               </Typography>
            ))}
         </Box>
      </Box>
   );
};
