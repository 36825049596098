import React, { FC, FormEvent, useState } from 'react';

import { useSaveLead } from '@hooks/hooksApi';
import { useProjectCustomFields } from '@hooks/hooksApi/project';
import { useToggleModal as useToggleAlert } from '@hooks/hooksUI';

import { AlertSnackbar, AlertClosable, AlertDefault } from '@shared/alert';
import { LoaderDefault } from '@shared/loader';
import { VisualScriptFormIsNoneTextField } from './components/visualScriptFormIsNoneTextField';
import { Box } from '@mui/material';
import { ErrorMessage } from '@shared/errorMessage';
import { ButtonDefault } from '@shared/buttons';

import { onSubmitFormIsNoneLead } from './utils';

import type { IProjectCustomFieldsDataItem } from '@projectTypes/projects';
import type { ApiInputType } from '@projectTypes/ui';
import type {
   RuntimeFieldsErrorsType,
   RuntimeFieldsType,
} from '../../../types/fieldsType';
import type { VisualScriptFormIsNoneLeadProps } from './type';

import { visualScriptFormIsNoneLeadStyles } from './style';

import { runtimeAdditionalFields } from '@constants/fields';

export const VisualScriptFormIsNoneLead: FC<VisualScriptFormIsNoneLeadProps> = ({
   dataStart,
}) => {
   const [formValues, setFormValues] = useState<RuntimeFieldsType>({});
   const [errors, setErrors] = useState<RuntimeFieldsErrorsType>({});
   const { isOpen: isOpenAlert, setIsOpen: setIsOpenAlert } = useToggleAlert();

   const {
      saveLeadData,
      isSaveLeadError,
      isSaveLeadLoading,
      isSaveLeadSuccess,
      saveLeadError,
   } = useSaveLead();

   const {
      projectCustomFieldsData,
      projectCustomFieldsIsError,
      errorMessage,
      projectCustomFieldsIsLoading,
      projectCustomFieldsIsSuccess,
   } = useProjectCustomFields({});

   if (projectCustomFieldsIsError)
      return <ErrorMessage message={errorMessage.data.message} />;

   if (projectCustomFieldsIsLoading) return <LoaderDefault />;

   const fields = [...runtimeAdditionalFields, ...(projectCustomFieldsData?.data ?? [])];

   const onSubmit = async (e: FormEvent) => {
      e.preventDefault();

      await onSubmitFormIsNoneLead({
         dataStart,
         formValues,
         saveLeadData,
         setErrors,
         setIsOpenAlert,
      });
   };

   const handleFieldChange = (
      name: string,
      value: string | { phoneNumber: string; countryCode: string },
   ) => {
      console.log(value);
      console.log(name);
      setFormValues((prev) => {
         if (typeof value === 'object' && 'phoneNumber' in value) {
            return { ...prev, [name]: value };
         }
         return { ...prev, [name]: value };
      });
   };

   return (
      <Box
         onSubmit={(e) => onSubmit(e)}
         sx={{ ...visualScriptFormIsNoneLeadStyles() }}
         component="form"
      >
         {isOpenAlert && !isSaveLeadLoading && (
            <AlertClosable
               open={true}
               message={isSaveLeadError ? 'error' : 'success'}
               severity={isSaveLeadError ? 'error' : 'success'}
            />
         )}

         {projectCustomFieldsIsSuccess &&
            fields.map((item: IProjectCustomFieldsDataItem) => (
               <VisualScriptFormIsNoneTextField
                  error={(errors?.[item.slug] as string) && 'это поле обязательное'}
                  name={item.name}
                  id={item.id}
                  placeholder={item.name}
                  key={item.id}
                  slug={item.slug}
                  type={item.type as ApiInputType}
                  required={item.required}
                  onChange={item.type === 'tel' ? handleFieldChange : handleFieldChange}
               />
            ))}
         <ButtonDefault type="submit">Отправить</ButtonDefault>
      </Box>
   );
};
