import React, { FC, useState } from 'react';

import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import { AlertClosableProps } from '../types';

export const AlertClosable: FC<AlertClosableProps> = ({
   severity = 'success',
   message,
   sx,
   sxIcon,
   styles,
   stylesIcon,
   Icon,
   variant = 'standard',
   open = true,
}) => {
   console.log(open);
   const [isOpen, setIsOpen] = useState(open);

   return (
      <>
         <Collapse in={isOpen}>
            <Alert
               variant={variant}
               sx={{ ...((sx ?? {}) as object), ...(styles as object) }}
               icon={
                  Icon && (
                     <Icon
                        sx={{ ...((sxIcon ?? {}) as object), ...(stylesIcon as object) }}
                        fontSize="inherit"
                     />
                  )
               }
               severity={severity}
               action={
                  <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                        setIsOpen(false);
                     }}
                  >
                     <CloseIcon fontSize="inherit" />
                  </IconButton>
               }
            >
               {message}
            </Alert>
         </Collapse>
      </>
   );
};
