import { SxProps, Theme } from '@mui/material';

export const sortsPopUpListStyle = ({ theme }: { theme: Theme }): SxProps<Theme> => ({
   display: 'flex',
   gap: '12.4px',
   flexWrap: 'wrap',
   [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '12.4px',
   },
});
