import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { ILeadResponse, ILeadCreateParams } from '@projectTypes/lead';

import {
   apiDashboard,
   apiDashboardLead,
   apiDashboardLeadRuntime,
} from '@constants/api/endpoints';

const baseUrl = process.env.REACT_APP_API;

export const leadApi = createApi({
   reducerPath: 'leadApi',
   baseQuery: fetchBaseQuery({
      baseUrl: `${baseUrl}${apiDashboard}`,
      prepareHeaders: (headers) => {
         headers.set('Content-Type', 'application/json');
         headers.set('Accept', 'application/json');
         const token = document.cookie
            .split('; ')
            .find((row) => row.startsWith('authToken='))
            ?.split('=')[1];
         if (token) {
            headers.set('Authorization', `Bearer ${token}`);
         }
         return headers;
      },
   }),
   tagTypes: ['Leads'],

   endpoints: (builder) => ({
      lead: builder.query<ILeadResponse, { page?: number }>({
         query: ({ page }) => ({
            url: `${apiDashboardLead}?page=${page ?? 1}`,
         }),
         providesTags: (result) => (result ? [{ type: 'Leads', id: 'LIST' }] : []),
      }),
      leadCreate: builder.mutation<void, ILeadCreateParams>({
         query: (newLead) => ({
            url: apiDashboardLead,
            method: 'POST',
            body: newLead,
         }),
         invalidatesTags: [{ type: 'Leads', id: 'LIST' }],
      }),
      leadDelete: builder.mutation<void, { id: string | number }>({
         query: ({ id }) => ({
            url: `${apiDashboardLead}/${id}`,
            method: 'DELETE',
         }),
         invalidatesTags: [{ type: 'Leads', id: 'LIST' }],
      }),
      leadRuntime: builder.query<ILeadResponse, void>({
         query: () => ({
            url: apiDashboardLeadRuntime,
         }),
      }),
   }),
});

export const {
   useLeadRuntimeQuery,
   useLeadQuery,
   useLeadCreateMutation,
   useLeadDeleteMutation,
} = leadApi;
